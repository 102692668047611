import { type ChangeEvent } from 'react';
import { type DropdownChangeEvent } from 'primereact/dropdown';
import clsx from 'clsx';

import SwitchableUnitField from 'components/SwitchableUnitField';
import { replaceItemAt } from 'helpers/Utils/collections';
import { getFixedValue } from 'modules/Blotter/Helpers';
import { PRICE_UNITS } from 'modules/Blotter/Models/Consts';

import type { SectionProps } from 'modules/Blotter/Models/SectionProps';
import type { TradeLeg } from 'modules/Blotter/Models/BlotterResponse';

interface DateTimeSectionProps extends SectionProps {
  leg: TradeLeg;
  legIndex: number;
}

export function DateTimeSection(props: DateTimeSectionProps): JSX.Element {
  const { leg, legIndex, mutate, request, shouldShowError } = props;

  return <section>
    <div className='section--datetime'>
      <SwitchableUnitField
        key='trade__price'
        className='form-input__container trade__price'
        inputType='number'
        label='Price*'
        inputProps={{
          className: clsx({ 'p-invalid': shouldShowError(`legs.${legIndex}.price.amount`) }),
          showError: shouldShowError(`legs.${legIndex}.price.amount`),
          keyfilter: 'num',
          type: 'number',
          value: `${leg.price.amount ?? ''}`,
          placeholder: 'Enter Price',
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            mutate({
              legs: replaceItemAt(request.legs, {
                ...leg,
                price: {
                  ...leg.price,
                  amount: getFixedValue(e.target.value)
                }
              }, legIndex)
            }, `legs.${legIndex}.price.amount`);
          }
        }}
        dropdownProps={{
          onChange: (e: DropdownChangeEvent) => mutate({
            legs: replaceItemAt(request.legs, {
              ...leg,
              price: {
                ...leg.price,
                unit: e.target.value
              }
            }, legIndex)
          }, `legs.${legIndex}.price.unit`),
          options: PRICE_UNITS,
          value: leg.price.unit,
        }}
      />
    </div>
  </section>;
}