type ExternalLinkHandlers = {
	openNewWindow: (path: string) => void
}

export const useExternalLink = () => {

	const openNewWindow = (path: string) => {
		window.open(path, "_blank", "noreferrer");
	}

	return {
		openNewWindow
	}

}