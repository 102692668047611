import { ReactElement, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DataView } from 'primereact/dataview';

import eventBus from 'server/EventBus';
import { DL_MESSAGES_STATUS_LABEL, DLMessagesStatus } from 'modules/DistList/Models/Enums';
import { shouldShowResendButton } from 'modules/DistList/Helpers';
import { DistListSignalEventTypes } from 'modules/DistList/Services/SignalRSocket';
import { SingleLineTruncated, SingleLineUnknown } from 'helpers/DataTable/Templates/ColumnTemplates';
import { replaceItemAt } from 'helpers/Utils/collections';

import DroppedTooltip from '../Message/DroppedTooltip';
import ResendButton from '../Message/ResendButton';
import MessageTemplateMobile from '../Message/MessageTemplateMobile';

import type {
  apiMailStatusUpdatedSignalR,
  apiSingleMailEmailDetails,
  apiSingleMailEmailDetailsFlat
} from 'modules/DistList/Models/apiResponse';

interface MessageProps {
  details: apiSingleMailEmailDetails | undefined;
  loading: boolean;
}

const SingleRecipientsDetails = (props: MessageProps): ReactElement => {
  const { details, loading } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const [messages, setMessages] = useState<apiSingleMailEmailDetailsFlat[]>([]);

  useEffect(() => {
    if (details) {
      const { recipients, ...detailsWithoutRecipients } = details;

      setMessages(details.recipients.map(r => ({ ...detailsWithoutRecipients, ...r })));
    } else {
      setMessages([]);
    }
  }, [details]);

  const handleUpdateStatus = (event: CustomEvent<apiMailStatusUpdatedSignalR>): void => {
    setMessages(msgs => {
      const { recipient } = event.detail;
      const index = msgs.findIndex(r => r.emailAddress === recipient.emailAddress);

      if (index > -1) {
        return replaceItemAt(msgs, { ...msgs[index], ...recipient }, index);
      }

      return msgs;
    });
  };

  useEffect(() => {
    eventBus.on(DistListSignalEventTypes.DIST_LIST_EMAIL_RECIPIENT_STATUS_CHANGED, handleUpdateStatus);

    return () => {
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_EMAIL_RECIPIENT_STATUS_CHANGED, handleUpdateStatus);
    };
  }, []);

  const onMessageResend = (data: apiSingleMailEmailDetailsFlat): void => {
    const index = messages.findIndex(m => m.emailAddress === data.emailAddress);

    setMessages(msgs => replaceItemAt(msgs, { ...msgs[index], status: DLMessagesStatus.processed }, index));
  }

  return <div className='single-message-recipients__container position--relative overflow--y'>
    {isMobile ?
      <DataView
        value={messages}
        itemTemplate={(el) => <MessageTemplateMobile
          item={el}
          onMessageResend={data => onMessageResend(data as apiSingleMailEmailDetailsFlat)}
          sentByVisible={false}
        />}
        className='distlist__view distlist__messages grow-to-fill no-background'
      />
      :
      <DataTable
        className='grow-to-fill'
        value={messages}
        loading={loading}
      >
        <Column
          header='Recipient'
          field='emailAddress'
          body={(data, config) => SingleLineTruncated(data, config, true)}
        />
        <Column
          header='Status'
          field='status'
          body={(data): JSX.Element =>
            [DLMessagesStatus.dropped].includes(data.status) ?
              <>{DL_MESSAGES_STATUS_LABEL[DLMessagesStatus.dropped]}<DroppedTooltip /></> :
              <>{DL_MESSAGES_STATUS_LABEL[data.status as DLMessagesStatus ?? DLMessagesStatus.null]}</>}
          className='column-status'
        />
        <Column
          header='Opening IP Address'
          field='ipAddress'
          body={SingleLineUnknown}
        />
        <Column
          header='Attachment Downloaded'
          field='attachmentDownloaded'
          body={(data): JSX.Element => data.attachmentDownloaded ? <i className='pi pi-check'></i> : <></>}
        />
        <Column header='Action'
          className='column-action'
          body={(data): JSX.Element =>
            shouldShowResendButton(data) ?
              <ResendButton
                data={data}
                callback={data => onMessageResend(data as apiSingleMailEmailDetailsFlat)}
              /> :
              <></>
          }
        />
      </DataTable>}
  </div>;
};

export default SingleRecipientsDetails;
