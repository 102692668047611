import { RefObject, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'primereact/button';

import DateTimeRange from 'components/DateTimeRange';

import BlotterSearchEntity from './BlotterSearchEntity';
import TradeSearchContainer from './TradeSearchContainer';
import ButtonExport from '../ExportTrades';

import type { SearchRequest, BlotterSearchField } from 'modules/Blotter/Models/SearchRequest';
import type { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';
import type { ToastMessageRef } from 'components/ToastMessage';

interface TradeSearchProps {
  isGridColumnsDefaultState: boolean;
  isLoading: boolean;
  searchItems: SearchRequest;
  toastRef: RefObject<ToastMessageRef>;
  worksheetId: string | undefined;
  addTrade: () => void;
  clearSearch: () => void;
  handleSearchEntityCallback: (data: BlotterSearchField[]) => void;
  handleDateTimeChangeCallback: (date: ParssedDateTimeResult | null) => void;
  handleExternalItemsChangeCallback: (items: BlotterSearchField[]) => void;
};

export default function TradeSearch(props: TradeSearchProps): JSX.Element {
  const {
    isGridColumnsDefaultState,
    isLoading,
    searchItems,
    toastRef,
    worksheetId,
    addTrade,
    clearSearch,
    handleSearchEntityCallback,
    handleDateTimeChangeCallback,
    handleExternalItemsChangeCallback,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [isParsingDate, setIsParsingDate] = useState<boolean>(false);

  const { dateTime, prices, quantities, clearingIds, searchRequestFields } = searchItems;
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  return <TradeSearchContainer isMobile={isMobile}>
    <div className='blotter-trade-search__container grow-to-fill' ref={containerRef}>
      <BlotterSearchEntity
        callback={handleSearchEntityCallback}
        handleExternalItemsChangeCallback={handleExternalItemsChangeCallback}
        searchContainerRef={containerRef}
        searchItems={searchItems}
      />
      <DateTimeRange
        placeholder='Trade date, time'
        defaultValue={dateTime?.original ?? ''}
        onDateParsed={(data) => {
          setIsParsingDate(false);
          handleDateTimeChangeCallback(data);
        }}
        onEmptyValue={() => handleDateTimeChangeCallback(null)}
        onDateParseError={() => setIsParsingDate(false)}
        onParsingStart={() => setIsParsingDate(true)}
        required={false}
      />
      <Button
        text
        size='small'
        onClick={clearSearch}
        disabled={!(dateTime || searchRequestFields?.length || prices?.length || quantities?.length || clearingIds?.length) || isParsingDate}
      >
        Clear
      </Button>
      {!isMobile && <>
        <Button
          size='small'
          onClick={addTrade}
        >
          Add trade
        </Button>
        <ButtonExport
          disabled={isLoading}
          isDefaultState={isGridColumnsDefaultState}
          toastRef={toastRef}
          worksheetId={worksheetId}
        />
      </>}
    </div>
  </TradeSearchContainer>;
}
