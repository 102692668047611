import { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ToastMessageRef } from 'components/ToastMessage';

import { useGetDistListById, useGetRecipients, useSearchMessages } from '../../Services/DistListService';
import { DistListSignalEventTypes } from '../../Services/SignalRSocket';

import MessageDesktop from './MessageDesktop';
import MessageMobile from './MessageMobile';

import { replaceItemAt } from 'helpers/Utils/collections';
import { parsePropsToDateTime } from 'helpers/Utils/misc';

import { DLMessagesStatus } from 'modules/DistList/Models/Enums';

import eventBus from 'server/EventBus';

import type { apiMailStatusUpdatedSignalR, apiSecureMailRecipientsResponse } from 'modules/DistList/Models/apiResponse';
import type { DistributionListMessagesSearchRequest } from '../../Models/distribution-list-create-request';
import type {
  DistributionListMessagesSearchResponse,
  DistributionListMessagesSearchResponseFlat
} from '../../Models/distribution-list-response';

interface MessageProps {
  handleClose: () => void;
  toast: RefObject<ToastMessageRef>;
  setActiveDetailTab: Dispatch<SetStateAction<number>>;
  activeDlId?: DistributionListMessagesSearchResponse['id'];
  dlName?: string;
  activeDetailTab?: number;
}

const Message = (props: MessageProps): JSX.Element => {
  const {activeDlId, handleClose, toast, dlName, activeDetailTab, setActiveDetailTab} = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [subjectSearch, setSubjectSearch] = useState<string>();
  const [messages, setMessages] = useState<DistributionListMessagesSearchResponse[] | undefined>(undefined);
  const [searchItems, setSearchItems] =
   useState<DistributionListMessagesSearchRequest>({ distributionListId: activeDlId ?? '' });

  const { searchResults, searchIsLoading } = useSearchMessages(searchItems);

  useEffect(() => {
    setSearchItems({
      distributionListId: activeDlId ?? '',
      subjectSearch: subjectSearch,
    });
  }, [activeDlId, subjectSearch, setSearchItems]);

  useEffect(() => {
    // Clear value on DL change
    setSearchValue('');
    setSubjectSearch(undefined);
  }, [activeDlId]);

  useEffect(() => {
    if (searchResults !== undefined && !searchIsLoading) {
      setMessages(searchResults);
    }
  }, [searchIsLoading, searchResults]);

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const {data, isLoading} = useGetDistListById(activeDlId);
  const [messagesUngrouped, setMessagesUngrouped] = useState<DistributionListMessagesSearchResponseFlat[]>([]);

  const { trigger: getRecipients, isMutating: getRecipientsMutating, error: getRecipientsError } = useGetRecipients();

  // Convert [{id: 1, recipients: [{status: a}, {status: b}]}] => [{id: 1, status: a}, {id: 1, status: b}]
  const convertMessages = useCallback((data?: DistributionListMessagesSearchResponse[]): DistributionListMessagesSearchResponseFlat[] => {
    const elements: DistributionListMessagesSearchResponseFlat[] = [];

    return data?.reduce((acc, cur) => {
      if (cur.recipients?.length) {
        return [...acc, ...cur.recipients.map((rec, i) => ({ ...cur, ...rec, recipientIndex: i, idKey: `${cur.id}_${i}` }))] as DistributionListMessagesSearchResponseFlat[];
      } else {
        return [...acc, { ...cur, recipientIndex: 0, idKey: `${cur.id}_0` }] as DistributionListMessagesSearchResponseFlat[];
      }
    }, elements) ?? [];
  }, []);

  useEffect(() => {
    setMessagesUngrouped(convertMessages(messages));
  }, [convertMessages, messages]);

  const fetchMessageWithRecipients = async (data: { id: string, distributionListId: string; }): Promise<void> => {
    const res = await getRecipients(data);

    setMessages(msgs => {
      if (msgs) {
        const index = msgs.findIndex(p => p.id === data.id);

        if (index !== -1) {
          return replaceItemAt(msgs, res, index);
        }
      }

      return msgs;
    });
  };

  const handleUpdateEmail = useCallback((event: CustomEvent<apiSecureMailRecipientsResponse>): void => {
    if (event.detail.distributionListId !== activeDlId) {
      return;
    }

    const index: number = messages?.findIndex(d => d.id === event.detail.id) ?? -1;

    if (index !== -1) {
    // fetch message details and it's recipients
    // Update existing
      fetchMessageWithRecipients({ id: event.detail.id, distributionListId: event.detail.distributionListId });
    } else {
      setMessages(msgs => {
        if (msgs) {
          // Add new item
          return [...msgs, parsePropsToDateTime(event.detail, ['messageDate'])].sort((a, b) =>
            b.messageDate.toMillis() - a.messageDate.toMillis());
        }

        return msgs;
      });
    }
    // eslint-disable-next-line
  }, [activeDlId, messages]);

  const handleUpdateStatus = (event: CustomEvent<apiMailStatusUpdatedSignalR>): void => {
    const { emailId, recipient } = event.detail;

    setMessages(msgs => {
      if (msgs) {
        const index = msgs.findIndex(m => m.id === emailId);

        if (index !== -1 && msgs[index]?.recipients) {
          const recipientIndex = msgs[index].recipients.findIndex(r => r.emailAddress === recipient.emailAddress);

          return replaceItemAt(
            msgs,
            { ...msgs[index], recipients: replaceItemAt(msgs[index].recipients, recipient, recipientIndex) },
            index
          );
        }
      }

      return msgs;
    });
  }

  useEffect(() => {
    eventBus.on(DistListSignalEventTypes.DIST_LIST_EMAIL_UPDATED, handleUpdateEmail);
    eventBus.on(DistListSignalEventTypes.DIST_LIST_EMAIL_RECIPIENT_STATUS_CHANGED, handleUpdateStatus);

    return () => {
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_EMAIL_UPDATED, handleUpdateEmail);
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_EMAIL_RECIPIENT_STATUS_CHANGED, handleUpdateStatus);
    };
  }, [handleUpdateEmail]);

  const onMessageResend = (data: DistributionListMessagesSearchResponseFlat): void => {
    const index: number | undefined = messages?.findIndex(i => i.id === data.id);

    if (index !== -1 && index !== undefined && messages !== undefined) {
      const updatedRecipients = replaceItemAt(messages[index].recipients,
        { ...messages[index].recipients[data.recipientIndex], status: DLMessagesStatus.processed }, data.recipientIndex);
      const updatedMessages = replaceItemAt(messages,
        { ...data, recipients: updatedRecipients }, index);
      setMessages(updatedMessages);
    }
  }

  return isMobile ?
    <MessageMobile
      onMessageResend={onMessageResend}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      setSubjectSearch={setSubjectSearch}
      isLoading={isLoading || searchIsLoading}
      isCreateDisabled={!data?.recipients.length}
      handleClose={handleClose}
      activeDlId={activeDlId}
      dlName={dlName}
      activeDetailTab={activeDetailTab}
      setActiveDetailTab={setActiveDetailTab}
      messagesUngrouped={messagesUngrouped}
      getRecipients={fetchMessageWithRecipients}
      getRecipientsMutating={getRecipientsMutating}
      getRecipientsError={getRecipientsError}
    /> :
    <MessageDesktop
      onMessageResend={onMessageResend}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      setSubjectSearch={setSubjectSearch}
      isLoading={isLoading || searchIsLoading}
      isCreateDisabled={!data?.recipients.length}
      handleClose={handleClose}
      activeDlId={activeDlId}
      toast={toast}
      setActiveDetailTab={setActiveDetailTab}
      messagesUngrouped={messagesUngrouped}
      getRecipients={fetchMessageWithRecipients}
    />;
};

export default Message;
