import { useCallback } from 'react';
import { Button } from 'primereact/button';

import { DLMessagesStatus } from '../../Models/Enums';
import { DistListApiService } from '../../Services/DistListService';

import type { DistributionListMessagesSearchResponseFlat } from '../../Models/distribution-list-response';
import type { apiSingleMailEmailDetailsFlat } from 'modules/DistList/Models/apiResponse';

export type ResendButtonFlatData = DistributionListMessagesSearchResponseFlat | apiSingleMailEmailDetailsFlat;

interface ResendButtonProps {
  data: ResendButtonFlatData;
  callback: (data: ResendButtonFlatData) => void;
}

const ResendButton = (props: ResendButtonProps): JSX.Element => {
  const { data, callback } = props;

  const resendMessage = useCallback((data: ResendButtonFlatData): void => {
    DistListApiService.Resend({
      emailId: data.id,
      requestingUserAddress: data.requestingUserEmailAddress,
      recipientAddress: data.emailAddress,
    });

    callback(data);
  }, [callback]);

  return (
    <Button
      size="small"
      text
      className="dl-resend no-background"
      onClick={():void => resendMessage(data)}
      disabled={[DLMessagesStatus.dropped].includes(data.status ?? DLMessagesStatus.null)}
    >
      Resend
    </Button>);
};

export default ResendButton;