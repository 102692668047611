import { ReactElement } from 'react';
import clsx from 'clsx';

import NotAvailable from 'components/NotAvailable';

import { DistListAttachmentStatus } from 'modules/DistList/Models/Enums';

import './SingleLineStatus.scss';

export const SingleLineStatus = <T,>(
  data: T,
  config: { field: string }
): ReactElement => {
  const [ status, container ] = config.field.split(',');
  const containerData = data[container as keyof typeof data];
  const statusData = parseInt(data[status as keyof typeof data] as string, 10);
  const isActive = statusData === DistListAttachmentStatus.Active;

  return (
    <>
      {Array.isArray(containerData) && containerData.length === 0 ? (
        <NotAvailable label='Empty' />
      ) : (
        <div className={clsx('sl-status', isActive ? 'status-positive' : 'status-negative')}>
          <i className={clsx('icon--small', isActive ? 'iconoir-check' : 'iconoir-xmark')} />
          <span>{ DistListAttachmentStatus[statusData as DistListAttachmentStatus]}</span>
        </div>
      )}
    </>
  );
};
