import { ReactNode, useMemo, useCallback, Fragment } from 'react';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';

import { getValueByPath } from 'helpers/Utils/misc';

import type { ShippingFixtureDetail, FixtureCargo } from 'modules/ShippingFixtures/Models/ShippingFixtures';
import { useFixtureSanctionDetails } from 'modules/ShippingFixtures/Services/hooks';
import SanctionSearchDetail from 'modules/ShippingFixtures/Components/SanctionSearchDetail';

import NotAvailable from 'components/NotAvailable';

import styles from './FixtureDetail.module.scss';

type ComponentProps = {
	fixtureid: string
}

type DefinitionItem = [string, string, DefinitionRenderingOpt?, boolean?];


enum DefinitionRenderingOpt {
	Normal = 0,
	DateTime = 1,
	NewLineListOfItems = 2,
	CommaListofItems = 3,
}



const FixtureDetail = ({fixtureid}: ComponentProps) => {

	const { data, error, isLoading } = useFixtureSanctionDetails(fixtureid)

	const definitions = useMemo<DefinitionItem[]>(() => {
		return [
			['Fixture ID', 'fixtureNumber', DefinitionRenderingOpt.Normal],
			['CP Date', 'charterPartyDate', DefinitionRenderingOpt.DateTime],
			['Vessel Name', 'vessel.name'],
			['IMO', 'vessel.imoNumber'],
			['Owner', 'owner.name'],
			['Charterer', 'charterer.name'],
			['Brokers', 'brokers.list'],
			['Cargo', 'cargos', DefinitionRenderingOpt.NewLineListOfItems],
			['Load Port(s)', 'loadPorts', DefinitionRenderingOpt.CommaListofItems],
			['Discharge Port(s)', 'dischargePorts', DefinitionRenderingOpt.CommaListofItems],
		]
	}, [data])

	const renderDefinition = useCallback((def: DefinitionItem): ReactNode => {
		
		if (!data) return<></>
		
		const [key, value, type ] = def;

		switch(type) {
			case DefinitionRenderingOpt.DateTime:
				return <>{ getValueByPath<ShippingFixtureDetail, DateTime>(data, value).toFormat('dd LLL yyyy') }</>
			case DefinitionRenderingOpt.NewLineListOfItems:
				
				const nlis = getValueByPath<ShippingFixtureDetail, FixtureCargo[]>(data, value);			
				return <>{ nlis.map( (cg, i) => <Fragment key={i}>{cg.details}<br/></Fragment> ) }</>;

			case DefinitionRenderingOpt.CommaListofItems:
				
				const cims = getValueByPath<ShippingFixtureDetail, string[]>(data, value);	
				return <>{ cims.length ? cims.join(', ') : <NotAvailable label='Not recorded' />}</>

			default:
				return <>{ getValueByPath<ShippingFixtureDetail, string>(data, value) }</>
		}
	}, [data]);

  return <>
		<div className={clsx(styles.container, 'direction--column')} >
			{ data &&
				<>
					<header>Fixture Details</header>
					<div className={clsx('definition-list__container', styles.detail)}>
						{ definitions.map(([key, value, type, fullwidth], index) =>
							<div key={index} className={clsx('definition-list__item', fullwidth && 'item--full-width')}>
								<dt>{ key }</dt>
								<dd>{ renderDefinition([key, value, type]) }
								</dd>
							</div>
						)}
					</div>
					<SanctionSearchDetail heading='Vessel' fixtureid={data.id} item={data.vessel} />
					<SanctionSearchDetail heading='Owner' fixtureid={data.id} item={data.owner} />
					<SanctionSearchDetail heading='Charterer' fixtureid={data.id} item={data.charterer} />
				</>
			}
		</div>
	</>

}

export default FixtureDetail;
