import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';

import { BorealisBarPortal } from 'components/BorealisBar';

import { ResultsModeEnum } from 'modules/Sanctions/Models/Enums';
import SanctionDetails from 'modules/Sanctions/Components/SanctionDetails';
import { useSanctionSearchSanpshot } from 'modules/ShippingFixtures/Services/hooks';

import styles from './Snapshot.module.scss';

type ComponentProps = {
  snapshotid: string,
	fixtureid: string,
  target: HTMLElement
}

const Snapshot = ({ target, snapshotid, fixtureid }: ComponentProps) => {
  
  const [ openState, setOpenState ] = useState<boolean>(false);

  const { data, error, isLoading } = useSanctionSearchSanpshot(snapshotid, fixtureid, openState);
  
  return <>
    <div className={styles.container}>
      <Button 
        text 
        size='small' 
        icon={`iconoir-nav-arrow-${openState ? 'down' : 'right'} icon--small`} 
        onClick={() => setOpenState(!openState)}
      />
      <label>Sanction Report Snaphot</label>
    </div>
    { data && openState &&
      <div className={styles.detail}>
        <SanctionDetails 
          selectedItem={data.snapshot}
          resultsMode={ResultsModeEnum.LegalEntitySanctions}
          opts={{
            parseProps: false,
            trackView: false
          }}
        />
      </div>
    }
    { isLoading && 
      <BorealisBarPortal target={target} />
    }
  </>;
}

export default Snapshot;
