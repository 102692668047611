
export enum SecurityRoles {
    Read = 0,
    Create = 1,
    Update = 2,
    Delete = 3,
    Share = 8,
    Export = 9,
    Import = 10,
    Base = 100,
}

// TODO: UPDATE, use proper numeration for export, share, delete, etc., add missing values
export enum SecurityRights {
    _Unprotected = -1, // allows to be visible to all users
    SuperAdmin = 11,
    Developer = 12,
    BetaAccess = 13,

    Admin = SecurityRoles.Base,
    Admin_Share = SecurityRights.Admin + SecurityRoles.Share,
    Admin_Export = SecurityRights.Admin + SecurityRoles.Export,
    Admin_Import = SecurityRights.Admin + SecurityRoles.Import,
    Admin_Create = SecurityRights.Admin + SecurityRoles.Create,
    Admin_Update = SecurityRights.Admin + SecurityRoles.Update,
    Admin_Delete = SecurityRights.Admin + SecurityRoles.Delete,

    Approvals = 200,
    Approvals_Export = SecurityRights.Approvals + SecurityRoles.Export,
    Approvals_Create = SecurityRights.Approvals + SecurityRoles.Create,

    CargoMovements = 300,
    CargoMovements_Export = SecurityRights.CargoMovements + SecurityRoles.Export,

    DistanceCalculator = 400,

    PositionList = 500,
    PositionList_Export = SecurityRights.PositionList + SecurityRoles.Export,
    PositionList_Update = SecurityRights.PositionList + SecurityRoles.Update,

    BalticRates = 600,

    RateGrids = 700,
    RateGrids_Export = SecurityRights.RateGrids + SecurityRoles.Export,
    RateGrids_Update = SecurityRights.RateGrids + SecurityRoles.Update,

    Sanctions = 800,
    Sanctions_Export = SecurityRights.Sanctions + SecurityRoles.Export,

    CargoTracker = 900,

    Surveillance = 1000,

    Onboarding = 1100,

    DistList = 1200,

    VortexaRates = 1300,

    Curves = 1400,

    Reports = 1500,

    JodiRates = 1600,

    IEARates = 1700,

    Blotter = 1800,

    DataManagement = 1900,

		FixtureSanctionsSearch = 2000,



		Random = Infinity
}
