import { MouseEventHandler, RefObject, useCallback } from 'react';
import clsx from 'clsx';
import { Button } from 'primereact/button';
import { ContextMenu } from 'primereact/contextmenu';

import { TradesDataResponse } from 'modules/Blotter/Models/BlotterResponse';

interface ITradeActionMenuProps {
  item: TradesDataResponse;
  setActionItem: (item: TradesDataResponse) => void;
  contextMenu: RefObject<ContextMenu>;
  className?: string;
}

const TradeActionMenu = ({
  item,
  setActionItem,
  className,
  contextMenu,
}: ITradeActionMenuProps): JSX.Element => {
  const onButtonClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e): void => {
      e.preventDefault();
      e.stopPropagation();

      setActionItem(item);
      /**
       * wait next tick for action item to be setup
       */
      setTimeout(() => contextMenu.current?.show(e), 0);
    },
    [item, contextMenu.current, setActionItem]
  );

  return (
    <Button
      className={clsx('transparent', className)}
      icon='iconoir-more-vert icon--tiny'
      onClick={onButtonClick}
      size='small'
      text
    />
  );
};

export { TradeActionMenu };
export default TradeActionMenu;
