import type { SuggestionItem } from 'components/Autocomplete';
import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';
import { SearchField, SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';
import { additionalSearchPropParser, fieldsParser, hydrator } from 'components/Worksheets/Models/Parsers';

import { apiSearchSingleEmailRequest } from './apiRequest';
import { SingleSearchGroup } from './Enums';

import { notNil } from 'helpers/Utils/misc';

export const singleSearchWorksheetParsers = {
  propsParser: additionalSearchPropParser,
  fieldsParser,
  hydrator,
};

// Check if search object is empty (undefined elements and empty arrays)
export const isSearchRequestEmpty = (items: apiSearchSingleEmailRequest): boolean => {
  const { pageNumber, pageSize, ...itemsFiltered } = items;
  return Object.values(itemsFiltered).every(
    el => !notNil(el) || (Array.isArray(el) && el.length === 0));
};

export const groupedEntityMapper = (group: string): ((item: SearchSuggestionsResponse) => SuggestionItem) =>
  ((item: SearchSuggestionsResponse): SuggestionItem => ({
    ...item,
    label: item.value,
    name: item.value,
    group: group,
  }));

export const selectedItemsMapper = (
  field: SearchField | SuggestionItem,
  group: string
): SuggestionItem => ({
  ...field,
  group: group,
  searchTerm: field.searchTerm || undefined,
  name: field.searchTerm || '',
  value: field.searchTerm || undefined,
  label: field.searchTerm,
});

export const createGroupItem = (value: string, group: SingleSearchGroup, searchField: EntitySearchFieldsEnum) => ({
  group: SingleSearchGroup[group],
  items: [{
    label: value,
    name: value,
    group: SingleSearchGroup[group],
    searchTerm: value,
    searchField: searchField
  }],
});
