import { useEffect, useRef, useState } from 'react';
import { Accordion, AccordionTab, type AccordionTabChangeEvent } from 'primereact/accordion';

import eventBus from 'server/EventBus';
import { BlotterSignalEventTypes } from 'modules/Blotter/Services/SignalRSocket';

interface TradeSearchContainerProps {
  children: JSX.Element;
  isMobile: boolean;
};

export default function TradeSearchContainer(props: TradeSearchContainerProps): JSX.Element {
  const { children, isMobile } = props;

  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const accordionRef = useRef<Accordion>(null);

  useEffect(() => {
    const onTableScroll = (): void => {
      if (activeIndex !== null) {
        setActiveIndex(null); // close accordion tab
      }
    };

    if (isMobile) {
      eventBus.on(BlotterSignalEventTypes.BLOTTER_TABLE_SCROLL, onTableScroll);
    }

    return () => {
      eventBus.remove(BlotterSignalEventTypes.BLOTTER_TABLE_SCROLL, onTableScroll);
    };
  }, [activeIndex, isMobile]);

  if (isMobile) {
    return <Accordion
      ref={accordionRef}
      activeIndex={activeIndex}
      onTabChange={(e: AccordionTabChangeEvent) => setActiveIndex(e.index as number | null)}
      className='blotter-trade-search__accordion'
    >
      <AccordionTab header='Search'>
        {children}
      </AccordionTab>
    </Accordion>;
  }

  return children;
}