import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import clsx from 'clsx';

import ToastMessage, { ToastMessageRef } from 'components/ToastMessage';

import DataModuleTable from './Components/DataModuleTable';
import SubscriptionDetails from './Components/SubscriptionDetails';

import { useGetSubscriptions } from './Services/DataModuleAPI';

import type { SubscriptionResponseFlat } from './Models/SubscriptionResponse';

import './DataModulePage.scss';

const DataModulePage = (): JSX.Element => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionResponseFlat[]>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionResponseFlat | null>(null);
  const [rightPanelOpen, setRightPanelOpen] = useState<boolean>(false);
  const toast = useRef<ToastMessageRef>(null);

  const { data, isLoading, isValidating, mutate: mutateSubscriptions } = useGetSubscriptions();

  useEffect(() => {
    if (data) {
      setSubscriptions(data.reduce((acc, curr) =>
        [...acc, ...curr.items.map(item => ({ ...item, clientName: curr.clientName }))],
        [] as SubscriptionResponseFlat[]
      ));
    }
  }, [data]);

  const addSubscription = (): void => {
    setSelectedSubscription(null);
    setRightPanelOpen(true);
  };

  const toggleDetails = (arg: SubscriptionResponseFlat | null) => {
    setSelectedSubscription(arg);
    setRightPanelOpen(!!arg);
  }

  return <>
    <header className='data-module__header'>
      <Button size='small' onClick={addSubscription}>Add subscription</Button>
    </header>
    <main
      className={clsx('data-module__main grow-to-fill', {
        'drawer--active': rightPanelOpen
      })}
      data-cols={rightPanelOpen ? '4,8' : '12,0'}
      data-drawer-style='slide'
      data-drawer-position='alongside-right'
    >
      <section className='overflow--hidden'>
        <DataModuleTable
          subscriptions={subscriptions}
          isLoading={isLoading || isValidating}
          selectedSubscription={selectedSubscription}
          setSelectedSubscription={toggleDetails}
        />
      </section>
      {rightPanelOpen && <aside className='position--relative'>
        <SubscriptionDetails
          selectedSubscription={selectedSubscription}
          closePanel={() => toggleDetails(null)}
          toastRef={toast}
          onSubscriptionUpdate={mutateSubscriptions}
        />
      </aside>}
      <ToastMessage ref={toast} />
    </main>
  </>;
};

export default DataModulePage;