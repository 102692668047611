import { createPortal } from 'react-dom'

import { clsx } from 'clsx';
import styles from './Bar.module.scss';



export const BorealisBar = (props: {styleOverrides?: string}): JSX.Element => {

	const { styleOverrides } = props;

  return <div className={clsx(styles.bar, styleOverrides)}></div>;
}

type BorealisPortalProps = {
	target: HTMLElement
}

export const BorealisBarPortal = ({target}: BorealisPortalProps) => {
	return createPortal(
		<BorealisBar />, target
	)
}


export default BorealisBar;
