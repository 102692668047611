import type { SearchField } from 'components/EntitySearch/Models/SearchEntities';

export type SurveillanceDetailsRequestParams = {
  id: string;
  partitionKey: string;
  providerName: string;
  searchRequestFields?: SearchField[];
};

export enum RequestSiblingMessagesDirection {
  Previous = 1,
  Next = 2
}

export type SurveillanceMessagesRequestParams = {
  id: string;
  partitionKey: string;
  direction: RequestSiblingMessagesDirection;
  pageNumber: number;
  pageSize: number;
};

export type SurveillanceStoreCopiedDetailsRequestParams = {
  fieldName: string;
  entity: SurveillanceDetailsRequestParams;
};

export type SurveillanceStorePlayedToEndRequestParams =
  SurveillanceDetailsRequestParams;

export type SurveillanceRecordingRequestParams = {
  id: string;
  userName: string;
  companyName: string;
  providerName: string;
  startTime: string;
};

export type SurveillanceTranscriptionCreateParams = {
  id: string;
  partitionKey: string;
  transcription: string;
};
