import type { SearchField } from 'components/EntitySearch/Models/SearchEntities';
import type { WorksheetMetaProps } from './WorksheetResponse';

export const additionalSearchPropParser = (
  props: WorksheetMetaProps[]
): WorksheetMetaProps[] => props.map(p => {
  try {
    return typeof p.value === 'string' ? { ...p, value: JSON.parse(p.value as string) } : p;
  } catch {
    return p;
  }
});

export const fieldsParser = (fields: SearchField[]): SearchField[] => fields.map(field => {
    const { metaData, searchField, ...rest } = field;

    const props = metaData?.reduce((acc: Record<string, any>, cur: WorksheetMetaProps) =>
      ({ ...acc, [cur.key as string]: cur.value }), {});

    return {
      ...rest,
      ...props,
      searchField: +searchField,
      metaData
    };
  }
);

export const hydrator = (): WorksheetMetaProps[] => [];


// ensure that props have stringified values
export const stringifyAdditionalProps = (mutation: any[]): WorksheetMetaProps[] =>
  mutation.map(item => typeof item.value === 'string' ? item : { ...item, value: JSON.stringify(item.value) });