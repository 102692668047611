import {
  forwardRef,
  useImperativeHandle,
  useState,
  type ForwardedRef,
} from 'react';
import { RadioButton, type RadioButtonChangeEvent } from 'primereact/radiobutton';

import { ExportOption } from 'modules/Blotter/Models/Enums';

import styles from './ExportDialogBody.module.scss';

export interface BodyRefProps {
  exportOption: ExportOption;
}

export const Body = forwardRef((props, ref: ForwardedRef<BodyRefProps>): JSX.Element => {
  const [exportOption, setExportOption] = useState<ExportOption>(ExportOption.Custom);

  useImperativeHandle(ref, () => ({
    exportOption
  }), [exportOption]);

  const onChange = (e: RadioButtonChangeEvent): void => setExportOption(e.value);

  return <div className={styles.dialogBody}>
    <strong>What to export</strong>
    <div>
      <RadioButton
        inputId='blotter-export-custom'
        name='custom'
        value={ExportOption.Custom}
        onChange={onChange}
        checked={exportOption === ExportOption.Custom}
      />
      <label htmlFor='blotter-export-custom'>Current view</label>
    </div>
    <div>
      <RadioButton
        inputId='blotter-export-default'
        name='default'
        value={ExportOption.Default}
        onChange={onChange}
        checked={exportOption === ExportOption.Default}
      />
      <label htmlFor='blotter-export-default'>Default view (all columns in default order)</label>
    </div>
  </div>;
});

export default Body;