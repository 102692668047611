import { useEffect, useRef, type RefObject } from 'react';
import { Button } from 'primereact/button';

import eventBus from 'server/EventBus';
import { ToastSeverity, type ToastMessageRef } from 'components/ToastMessage';
import { useExportTrades } from 'modules/Blotter/Services/BlotterAPI';
import { ExportOption } from 'modules/Blotter/Models/Enums';
import { GlobalDialogDisplayEvents } from 'models/shared/DialogDisplay';

import Body, { type BodyRefProps } from './ExportDialogBody';
import Footer, { type FooterRefProps } from './ExportDialogFooter';

interface ButtonExportProps {
  disabled: boolean;
  isDefaultState: boolean;
  toastRef: RefObject<ToastMessageRef>;
  worksheetId: string | undefined;
};

export default function ButtonExport(props: ButtonExportProps): JSX.Element {
  const { disabled, isDefaultState, toastRef: toast, worksheetId } = props;

  const dialogFooterRef = useRef<FooterRefProps>(null);
  const dialogBodyRef = useRef<BodyRefProps>(null);

  const { isMutating: isExporting, trigger: exportTrades } = useExportTrades();

  useEffect(() => {
    if (dialogFooterRef.current) {
      dialogFooterRef.current.setLoading(isExporting);
    };
  }, [isExporting]);

  const handleExport = async (): Promise<void> => {
    try {
      if (worksheetId) {
        await exportTrades({ worksheetId, exportOption: dialogBodyRef.current?.exportOption ?? ExportOption.Default });
        handleCancel();
      } else {
        throw new Error('No worksheet ID'); // unlikely to happen
      }
    } catch (e) {
      toast.current?.replace({
        title: 'Error',
        message: 'Sorry, can\'t export the trades. Please try again later.',
        severity: ToastSeverity.ERROR,
      });
    }
  };

  const handleCancel = (): void => {
    eventBus.dispatch(GlobalDialogDisplayEvents.HIDE, null);
  }

  const showExportDialog = (): void => {
    eventBus.dispatch(GlobalDialogDisplayEvents.DISPLAY, {
      header: 'Export',
      body: <Body
        ref={dialogBodyRef}
      />,
      footer: <Footer
        ref={dialogFooterRef}
        onExport={handleExport}
        onCancel={handleCancel}
      />,
      size: 'large',
    });
  };

  const onExportButtonClick = (): void => {
    if (isDefaultState) {
      handleExport();
    } else {
      showExportDialog();
    }
  }

  return <Button
    outlined
    size='small'
    icon='iconoir-download icon--small'
    loading={isExporting}
    disabled={disabled}
    onClick={onExportButtonClick}
  >
    Export
  </Button>;
};