import GridElement from 'components/GridElement';

import type { TradesDataResponse } from 'modules/Blotter/Models/BlotterResponse';
import type { ColumnConfig } from 'modules/Blotter/Models/GridConfig';

interface TradeItemProps {
  activeColumns: ColumnConfig[];
  data: TradesDataResponse;
  handleRowClick: (value: TradesDataResponse) => void;
}

const TradeItem = (props: TradeItemProps): JSX.Element => {
  const { activeColumns, data, handleRowClick } = props;

  return (
    <div className='trade-item' onClick={(): void => handleRowClick(data)}>
      {activeColumns.map(item =>
        <GridElement
          key={item.id}
          {...item.gridProps}
          body={item.gridProps.body(data)}
        />
      )}
    </div>
  );
};

export { TradeItem };
export default TradeItem;
