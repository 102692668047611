import { ReactElement } from 'react';
import * as React from 'react';
import { Button } from 'primereact/button';

interface NewSingleMessageButtonProps {
  handleCreateNewMessage: () => void;
}

const NewSingleMessageButton = (props: NewSingleMessageButtonProps): ReactElement => {
  const { handleCreateNewMessage } = props;

  return (
    <Button
      size="small"
      className="distlist-add no-background"
      onClick={handleCreateNewMessage}
    >
      Create New Message
    </Button>);
};


export default NewSingleMessageButton;