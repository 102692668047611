import {
  Dispatch,
  ReactElement,
  RefObject,
  SetStateAction,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';

import { ToastMessageRef } from 'components/ToastMessage';
import { useGetSingleMessageDetails } from 'modules/DistList/Services/DistListService';

import { deferNextAction } from '../../../CargoTracker/Components/CargoEditWarningDialog';
import { DistListWarningDialogEvents } from '../CloseWarningDialog';

import SingleMessageDetails from './SingleMessageDetails';
import SingleNewMessage from './SingleNewMessage';
import SingleRecipientsDetails from './SingleRecipientsDetails';

import './SingleSidePanel.scss';

interface SidePanelProps {
  isNew: boolean;
  activeDetailTab: number;
  setActiveDetailTab: Dispatch<SetStateAction<number>>;
  handleClose: () => void;
  toast: RefObject<ToastMessageRef>;
  activeSingle?: string | null;
}

export const SIDE_PANELS = ['Message Details', 'Recipients'];

const SingleSidePanel = (props: SidePanelProps): ReactElement => {
  const { activeSingle, isNew, handleClose, toast, activeDetailTab, setActiveDetailTab } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const gestures = useSwipeable({
    onSwipedRight: ():void => {
      handleCloseSidePanel();
    },
  });

  const { details, /* detailsError, */ detailsIsLoading } = useGetSingleMessageDetails(activeSingle);

  const handleCloseSidePanel = ():void => {
    setActiveDetailTab(0);
    handleClose();
  };

  const handleTabChange = async (e: TabViewTabChangeEvent): Promise<void> => {
    const result = await deferNextAction(DistListWarningDialogEvents.ACTION,
      null,
      () => setActiveDetailTab(e.index));
    if (result) {
      return;
    }

    setActiveDetailTab(e.index);
  };

  return (
    <aside
      className="distlist-single-side-panel position--relative direction--column"
      {...gestures}
    >
      {isNew ? (
        <SingleNewMessage handleCloseSidePanel={handleClose} toast={toast} />
      ) : (
        <>
          {isMobile ? (
            <>
              <Button
                size="small"
                text
                className="plain-text back-button"
                icon={'iconoir-nav-arrow-left icon--small'}
                onClick={handleClose}
              >
                Back to list
              </Button>
              <div className="form-input__container">
                <Dropdown
                  id="dl-side-panel-mode"
                  value={activeDetailTab ?? 1}
                  onChange={(e: DropdownChangeEvent): void =>
                    setActiveDetailTab(e.value)
                  }
                  options={SIDE_PANELS.map((el, index) => ({
                    label: el,
                    value: index,
                  }))}
                />
              </div>
              {activeDetailTab === 0 ?
                <SingleMessageDetails
                    details={details}
                    loading={detailsIsLoading}
                /> :
                <SingleRecipientsDetails
                    details={details}
                    loading={detailsIsLoading}
                />}
            </>
          ) : (
            <>
              <TabView
                renderActiveOnly={true}
                activeIndex={activeDetailTab}
                onTabChange={handleTabChange}
                className="distlist-side-panel-tabs"
              >
                <TabPanel header={SIDE_PANELS[0]}>
                  <SingleMessageDetails
                        details={details}
                        loading={detailsIsLoading}
                  />
                </TabPanel>
                <TabPanel
                  header={SIDE_PANELS[1]}
                      contentClassName="direction--column position--relative"
                >
                  <SingleRecipientsDetails
                        details={details}
                        loading={detailsIsLoading}
                  />
                </TabPanel>
              </TabView>
              <Button
                text
                icon="iconoir-xmark icon--tiny p-button-icon-only"
                className="close-button"
                onClick={handleCloseSidePanel}
              />
            </>
          )}
        </>
      )}
    </aside>
  );
};

export default SingleSidePanel;