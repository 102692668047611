import { DateTime } from 'luxon';

import type { ColumnBodyOptions, Column } from 'primereact/column';


type RenderingOpts = {
	includeLabel: boolean;
}

const defaultOpts: RenderingOpts = {
	includeLabel: false
}

import styles from './ReadableDate.module.scss';
import general from './General.module.scss';

export const ReadableDate = <T,>(data: T, config: {field: string}, format?: string, opts: RenderingOpts = defaultOpts): JSX.Element => {

  const field = config.field;
  const date: DateTime = data[field as keyof T] as DateTime;

	let col: Column = (config as ColumnBodyOptions).column

  return <>
		{ opts.includeLabel &&
			<label className={ general.label }>{ col.props.header as string }</label>
		}
    { date.isValid
      ? <span>{ date.toFormat(format ?? 'dd LLL yyyy, hh:mm')}</span>
      : <i className={styles.unknownDate}>Unknown</i>
    }
  </>;
};