import { clsx } from "clsx";
import { ColumnBodyOptions } from "primereact/column";

import styles from "./DoubleLine.module.scss";
import general from "./General.module.scss";
import { Flag } from "components/Flag";

type DisplayOptions = {
  uppercase?: boolean;
  hideSecondLineOnSmallScreens?: boolean;
}

const defaultOpts: DisplayOptions = {
  uppercase: false,
  hideSecondLineOnSmallScreens: false
}

/**
 * General custom cell rendering function for the <DataTable> instances. Used to display
 * 2 points of related data (eg vessel name, and IMO Number) over two lines. Optional
 * third value will render a flag (assumes 3 char ISO country code)
 *
 * @param {T} data 
 * @param {ColumnBodyOptions} config
 *
 */
export const DoubleLineFlagged = <T,>(data: T, config: ColumnBodyOptions, opts?: DisplayOptions) => {

  const [ upper, lower, flag ] = config.field.split(",");
  const { uppercase, hideSecondLineOnSmallScreens } = { ...defaultOpts, ...opts };

  return <div className={clsx(
    "align-items--start",
    styles.cell,
    styles.flagged,
  )}>
    <span className={clsx(
      "upper", styles.upper, general.truncated,
      uppercase && styles.cap
    )}>
      {`${ data[upper as keyof T]}`}
    </span>
    <span 
      title={`${ data[lower as keyof T]}`} 
      className={
        clsx("lower", styles.lower, hideSecondLineOnSmallScreens && styles.aa)
      }>
        {`${ data[lower as keyof T]}`}
    </span>
    { flag && data[flag as keyof T] !== "UNK" &&
			<div>
				<Flag code={data[flag as keyof T] as string}  />
			</div>
    }
  </div>
}

