import axios, { AxiosResponse } from 'axios';

import { parsePropsToDateTime } from 'helpers/Utils/misc';
import { getAllFromBitwise } from 'helpers/Utils/enum';


import { parseVesselProps, parseEntityProps } from 'modules/Sanctions/Models/Parsers';
import { apiLegalEntityResponse } from 'modules/Sanctions/Models';

import type { apiVesselSanctionResponse } from 'modules/Sanctions/Models';

import type { 
  apiFixtureAuditSummaries, apiFixtureAuditFixture, apiAuditScore,
  fixtureAuditItem, apiFixtureAuditSnapshot 
} from '../Models/apiResponse';
import type { 
  ShippingFixtureSummary, ShippingFixtureSummaries, FixtureSearchAudit, 
  FixtureVesselAudit, ShippingFixtureDetail, AuditEntityItemDetailed 
} from '../Models/ShippingFixtures';
import type { Snapshot } from '../Models/Snapshots';
import { AuditScoreType, SanctionSnapshotType } from '../Models/Enums';

export class ShippingFixturesService {

  static GET_FIXTURES_URL: string = 'fixtureaudit/summary';
  static GET_DETAILED_FIXTURE_URL: string = 'fixtureaudit/fixture';
  static GET_SNAPSHOT_URL: string = 'fixtureaudit/snapshot';

  static getFixtures = ({skip, take}: {skip: number, take: number}) => {

    return axios.request<ShippingFixtureSummaries, AxiosResponse<apiFixtureAuditSummaries>>({
      url: `${ShippingFixturesService.GET_FIXTURES_URL}/${skip}/${take}`, 
      method: 'GET'
    })
    .then(({data}) => {

      const { items: fixtures, totalRecords, count } = data;

      const items = fixtures.map(
        (item) => {

          const { vessel, charterer, owner } = item;

          const updated = {
            ...item,
            // cargoDetails: `${new Intl.NumberFormat('en-US', 
            //   { notation:'compact', maximumSignificantDigits: 3}).format(item.cargoSize)} ${item.cargoType}`,
            vessel: ShippingFixturesService.parseSummaryItem<FixtureVesselAudit>(vessel, true),
            charterer: ShippingFixturesService.parseSummaryItem<FixtureSearchAudit>(charterer),
            owner: ShippingFixturesService.parseSummaryItem<FixtureSearchAudit>(owner),
            brokers: {
              users: [...item.brokers], list: item.brokers.map(({name}) => name).join(', ')
            }
          }

          return parsePropsToDateTime<ShippingFixtureSummary>(updated, ['charterPartyDate'])
        }
      );

      return { items, totalRecords, count } as ShippingFixtureSummaries
    })
    .catch(e => { throw e })
  }

  static getFixtureDetails = (fixtureid: string) => axios.request<any, AxiosResponse<apiFixtureAuditFixture>>({
    url: `${ShippingFixturesService.GET_DETAILED_FIXTURE_URL}/${fixtureid}`,
    method: 'GET'
  }).then(({data}) => {

    const { vessel, owner, charterer, brokers, cargos } = data;

    const updated = {
      ...data,
      vessel: ShippingFixturesService.parseDetailedItem(vessel, true),
      owner: ShippingFixturesService.parseDetailedItem(owner),
      charterer: ShippingFixturesService.parseDetailedItem(charterer),
      brokers: {
        users: [...brokers], list: brokers.map(({name}) => name).join(', ')
      },
      cargos: cargos.sort((a, b) => a.parcel - b.parcel).map( cargo => ({
        ...cargo,
        details: `${cargo.quantity ? `${new Intl.NumberFormat('en-US', { notation:'compact', maximumSignificantDigits: 3}).format(cargo.quantity)} ` : ''}${cargo.description}` 
      }))
    }

    return parsePropsToDateTime<ShippingFixtureDetail>(updated, ['charterPartyDate'])
  })
  .catch(e => { throw e })

  static getSnaphot = (fixtureid: string, snapshotid: string) => axios.request<any, AxiosResponse<apiFixtureAuditSnapshot>>({
      url: `${ShippingFixturesService.GET_SNAPSHOT_URL}/${fixtureid}/${snapshotid}`, 
      method: 'GET', 
    })
    .then(async ({data}) => {

      let { sanctionType, snapshot } = data;

			snapshot = Object.fromEntries(Object.entries(snapshot).map(
				([k, v]) => [`${k.charAt(0).toLowerCase()}${k.slice(1)}`, v])
			) as apiVesselSanctionResponse | apiLegalEntityResponse


      return { 
				sanctionType,
				snapshot: sanctionType === SanctionSnapshotType.Entity 
					? parseEntityProps(snapshot as apiLegalEntityResponse)
					: parseVesselProps(snapshot) 
      } as Snapshot;
    })
    .catch((e) => {
      console.log("e", e)
      throw e;
    })

  
  /**
   * Takes a basic primitive `apiAuditScore` instance creating additional properties
   * needed to match a return `FixtureSearchAudit` or `FixtureVesselAudit` 
   * summary objects
   *
   * @private
   * @param {apiAuditScore} item
   * @returns {T} Either a FixtureVesselAudit or FixtureSearchAudit instance
   */
  private static parseSummaryItem = <T = FixtureSearchAudit>(item: apiAuditScore | fixtureAuditItem, isVessel: boolean = false): T => {

    const parsed = {
      ...item,
      majorStatus: getAllFromBitwise(AuditScoreType, item.score)[0],
    }

    return (isVessel ? { ...parsed, imoNumber: item.value } : parsed) as T
  }

	
  /**
	 * Takes a basic primitive `fixtureAuditItem` instance so appropriate properties
	 * such as a snaphots `when` value are transposed into DateTime instances
	 *
	 * @template [T=AuditEntityItemDetailed]
	 * @param {fixtureAuditItem} item
	 * @param {boolean} [isVessel=false]
	 * @returns {T}
	 */
	private static parseDetailedItem = <T = AuditEntityItemDetailed>(item: fixtureAuditItem, isVessel: boolean = false):T => {

    const parsed = {
      ...ShippingFixturesService.parseSummaryItem<T>({...item }, isVessel),
      snapshots: item.snapshots.map( item => parsePropsToDateTime(item, ['when'])),
    }

		return parsed
  }
}


