import type { ColumnBodyOptions, Column } from 'primereact/column';

import general from './General.module.scss';
import { getValueByPath } from 'helpers/Utils/misc';


type TemplateProps<T> = {
	data: T;
	config: ColumnBodyOptions;
	opts?: RenderingOpts
}

type RenderingOpts = {
	includeLabel: boolean;
}

const defaultOpts: RenderingOpts = {
	includeLabel: false
}



export const SingleLine = <T,>(props: TemplateProps<T>) => {

	const { data, config } = props;
	const opts = { ...defaultOpts, ...props.opts };

	const content = getValueByPath(data, config.field)

	return <>
		{ opts.includeLabel &&
			<label className={ general.label }>{ config.column.props.header as string }</label>
		}
		{ content }
	</>
}