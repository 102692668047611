import { TradeItemAction, TradeType } from './Enums';
import { getValueCollection } from 'helpers/Utils/enum';

import type { SearchRequest } from './SearchRequest';

export const TRADES_DATA_KEY = 'blotter-trades-data';

export const GRID_CONFIG_NAME = 'blotter';

export const GRID_TEMPLATE_COLUMNS_CSS_VAR_NAME = '--blotterGridTemplateColumns';

export const BLOTTER_HEADER_CLASSNAME_HIDDEN = 'blotter__header--hidden';

export const PRICE_UNITS = ['$', 'WS'];

export const QUANTITY_UNITS = ['KT/month', 'KT/week'];

export const TRADE_TYPES = getValueCollection(TradeType).map(t => t.key);

export const DEFAULT_PRICE = {
  amount: null,
  unit: PRICE_UNITS[1],
};

export const EMPTY_SEARCH_PARAMS: SearchRequest = {
  searchRequestFields: [],
  dateTime: null,
  prices: null,
  quantities: null,
  orderByFields: [],
};

export const TRADE_ITEM_MENU_ICONS: Record<TradeItemAction, string> = {
  [TradeItemAction.Clone]: 'copy',
  [TradeItemAction.Copy]: 'multiple-pages',
};

export const SEARCH_EMPTY_MESSAGE = 'Sorry. No matches for that search…';

export const BLOTTER_PAGE_SIZE = 1000;

export const MOBILE_GRID_ROW_HEIGHT = 260; // px

export const MOBILE_HEADER_HEIGHT = 221; // px
