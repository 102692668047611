import clsx from 'clsx';

import './GridElement.scss';

export interface GridElementProps {
  header: string;
  body: string | JSX.Element | undefined;
  className?: string;
  hidden?: boolean;
}

const GridElement = (props: GridElementProps): JSX.Element | null => {
  const { className, header, body, hidden } = props;

  if (hidden) {
    return null;
  }

  return (
    <div className={clsx('grid-element', className)}>
      <div className='grid-element__header'>{header}</div>
      <div className='grid-element__body'>{body ?? '-'}</div>
    </div>
  );
};
export default GridElement;