import { type UIEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { Badge } from 'primereact/badge';
import { Checkbox } from 'primereact/checkbox';
import { Column, type ColumnBodyOptions } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { DataTable, type DataTablePropsSingle, type DataTableSelectionSingleChangeEvent, type DataTableStateEvent } from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';
import { type VirtualScrollerChangeEvent } from 'primereact/virtualscroller';

import eventBus from 'server/EventBus';
import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';
import { ServiceCallError } from 'components/Errors/ServiceCalls';
import { ConfigurationTabs, GridColumnConfiguration, GridConfigPanelEvents, GridConfigurationType } from 'components/GridColumnConfiguration';
import { useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';
import { DoubleLineSimple, ReadableDate, SingleLineTruncated, SingleLineWithAddon } from 'helpers/DataTable/Templates/ColumnTemplates';
import { ServerSortHeader } from 'helpers/DataTable/Templates/Headers';
import { removeItemAt, replaceItemAt } from 'helpers/Utils/collections';
import { getValueCollection } from 'helpers/Utils/enum';
import { getGridColumnsTemplates } from 'helpers/Utils/misc';
import { camelToSpace, formatName } from 'helpers/Utils/string';
import TradeItem from 'modules/Blotter/Components/TradeDetails/Templates/TradeItem';
import {
  GRID_CONFIG_NAME,
  GRID_TEMPLATE_COLUMNS_CSS_VAR_NAME,
  SEARCH_EMPTY_MESSAGE,
  TRADE_ITEM_MENU_ICONS,
} from 'modules/Blotter/Models/Consts';
import {
  SortableFieldName,
  SortOrder,
  TradeItemAction,
  TradeType,
} from 'modules/Blotter/Models/Enums';
import { DEFAULT_GRID_ROW_HEIGHT } from 'models/shared/consts';

import TradeActionMenu from '../TradeActionMenu/TradeActionMenu';
import TypeChip from './Templates';

import type { GridConfiguration } from 'components/OBXUser/Model/ProfileResult';
import type { TradesDataResponse } from 'modules/Blotter/Models/BlotterResponse';
import type { SearchRequest, BlotterSearchField, SortByFields } from 'modules/Blotter/Models/SearchRequest';
import type { AdditionalGridConfigProps, ColumnConfig } from 'modules/Blotter/Models/GridConfig';
import type { AxiosError } from 'axios';

import './BlotterDataTable.scss';

interface BlotterDataTableProps {
  isLoading: boolean;
  onLazyLoad: (e: VirtualScrollerChangeEvent) => void;
  selectedTrade: TradesDataResponse | null;
  onTradeSelected: (arg: TradesDataResponse) => void;
  onTableScroll: (e: UIEvent<HTMLElement>) => void;
  trades: TradesDataResponse[] | undefined;
  orderByColumns: SortByFields[];
  setOrderByColumns: (arg: SortByFields[]) => void;
  searchItems: SearchRequest;
  handleSearchEntityCallback: (fields: BlotterSearchField[]) => void;
  onItemAction: (item: TradesDataResponse, action: TradeItemAction) => void;
  setIsDefaultState: (arg: boolean) => void;
  error?: AxiosError;
}

const BlotterDataTable = (props: BlotterDataTableProps): JSX.Element => {
  const {
    error,
    isLoading,
    onLazyLoad,
    onTableScroll,
    selectedTrade,
    onTradeSelected,
    orderByColumns,
    setOrderByColumns,
    searchItems,
    handleSearchEntityCallback,
    onItemAction,
    setIsDefaultState,
    trades,
  } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const [actionItem, setActionItem] = useState<
    TradesDataResponse | undefined
  >();

  const [config, setConfig] = useState<GridConfiguration<AdditionalGridConfigProps>>();
  const [activeColumns, setActiveColumns] = useState<ColumnConfig[]>([]);
  const [initialColumnTemplates, setInitialColumnTemplates] = useState<string[]>();
  const [columnTemplates, setColumnTemplates] = useState<string>();
  // itemSize (row height) has to be calculated because the height changes when toggling columns on mobile
  const [tradeItemSize, setTradeItemSize] = useState<number | undefined>(undefined);

  const dataTableRef = useRef<DataTable<TradesDataResponse[]>>(null);

  const { getGridConfig } = useLoadUserSettings();

  const cm = useRef<ContextMenu>(null);
  const contextMenuActions = useRef<ContextMenu>(null);

  useEffect(() => {
    const configuration = getGridConfig<AdditionalGridConfigProps>(GRID_CONFIG_NAME);

    setConfig(configuration.columns.length ?
      configuration :
      // if there are no columns in config that means it's default config
      // display all columns in that case
      { ...configuration, columns: allColumnsConfig.map(col => col.id!) }
    );
  }, []);

  const sortColumns = (order?: AdditionalGridConfigProps['order']) => (a: ColumnConfig, b: ColumnConfig): number => {
    if (isMobile) {
      // keep 'instrument' and 'actions' elements as top elements
      if (a.id === 'actions' && b.id === 'instrument') {
        return 1;
      }

      if (a.id === 'actions') {
        return -1;
      }
    }

    if (a.id === 'actions') {
      return 1;
    }

    if (order) {
      return order.findIndex(c => c.id === a.id) - order.findIndex(c => c.id === b.id);
    }

    return 0;
  };

  const updateFilter = (value: string): void => {
    if (searchItems.searchRequestFields?.find(i => i.searchTerm === value)) {
      handleSearchEntityCallback(searchItems.searchRequestFields.filter(i => i.searchTerm !== value));
    } else {
      handleSearchEntityCallback([...searchItems.searchRequestFields ?? [], {
        searchTerm: value,
        searchField: EntitySearchFieldsEnum.TradeType,
        metaData: []
      }]);
    }
  };

  const contextMenuItems: MenuItem[] = useMemo(() => getValueCollection(TradeType, false).map(({ key, value }) => ({
    command: () => updateFilter(value as string),
    template: () => <>
      <Checkbox
        id={`blotter-table-filters--${key}`}
        checked={!!searchItems.searchRequestFields?.find(f => f.searchTerm === value && f.searchField === EntitySearchFieldsEnum.TradeType)}
      />
      <label htmlFor={`blotter-table-filters--${key}`}>{key}</label>
    </>
  })), [searchItems]);

  const contextMenuActionsItems = useMemo(() => {
    if (actionItem) {
      return getValueCollection(TradeItemAction, false).map(({ key }) => ({
        icon: `iconoir-${TRADE_ITEM_MENU_ICONS[key as TradeItemAction]
          } icon--small`,
        label: camelToSpace(key.toString(), { lower: true }).replace(/^.?/, m =>
          m.toUpperCase()
        ),
        command: (): void => onItemAction(actionItem, key as TradeItemAction),
      }));
    }
    return undefined;
  }, [actionItem, onItemAction]);

  const filterCount = useMemo(() =>
    searchItems.searchRequestFields?.filter(item => item.searchField === EntitySearchFieldsEnum.TradeType).length ?? 0,
    [searchItems.searchRequestFields]
  );

  const handleOrderChange = useCallback((event: DataTableStateEvent) => {
    let newSortOrder: SortByFields[];
    const sortField = event.sortField as SortableFieldName;
    const index = orderByColumns.findIndex(item => item.fieldName === sortField);
    const item = orderByColumns[index];

    //  This sortField doesn't exist in our ordering - so add it
    if (!item) {
      newSortOrder = [...orderByColumns, { fieldName: sortField, sortOrder: SortOrder.Asc }];
    } else {
      switch (item.sortOrder) {
        case SortOrder.Asc:
          //  Switch to descending
          newSortOrder = replaceItemAt<SortByFields>(orderByColumns, { fieldName: sortField, sortOrder: SortOrder.Desc }, index);
          break;
        case SortOrder.Desc:
          //  …or remove completely
          newSortOrder = removeItemAt<SortByFields>(orderByColumns, index);
          break;
        default:
          newSortOrder = orderByColumns;
      }
    }

    setOrderByColumns(newSortOrder);
  }, [orderByColumns, setOrderByColumns]);

  const sortOrder = useMemo(
    // map order to match <ServerSortHeader /> `current` prop type
    () => (orderByColumns ?? []).map(i => `${i.fieldName} ${SortOrder[i.sortOrder].toLowerCase()}`),
    [orderByColumns]
  );

  const allColumnsConfig = useMemo((): ColumnConfig[] => [
    {
      label: 'Instrument',
      id: 'instrument',
      name: 'Instrument',
      disabled: true,
      pinned: true,
      columnProps: {
        body: (data, config) => SingleLineTruncated(data, config, true),
        field: 'instrument',
        headerClassName: 'sorting--server-side',
        header: <ServerSortHeader
          label='Instrument'
          by={SortableFieldName.Instrument}
          current={sortOrder}
        />,
        sortable: true,
        sortField: SortableFieldName.Instrument,
      },
      gridProps: {
        className: 'grid-element__instrument',
        header: 'Instrument',
        body: (data) => data.instrument,
      }
    },
    {
      label: 'Type',
      id: 'type',
      name: 'Type',
      columnProps: {
        body: TypeChip,
        field: 'type',
        headerClassName: 'sorting--server-side',
        header: <>
          <ServerSortHeader
            label='Type'
            by={SortableFieldName.Type}
            current={sortOrder}
          />
          <div className='blotter-data-table__filter-icon--wrapper' onClick={(e) => cm.current?.show(e)}>
            <i className='iconoir-filter icon--tiny' />
            {!!filterCount && <Badge value={filterCount} />}
            <ContextMenu className='blotter-data-table__filter-menu' ref={cm} model={contextMenuItems} />
          </div>
        </>,
        sortable: true,
        sortField: SortableFieldName.Type
      },
      gridProps: {
        className: 'grid-element__type',
        header: 'Type',
        body: (data) => TypeChip(data, { field: 'type' } as ColumnBodyOptions)
      }
    },
    {
      label: 'Broker (Buy)',
      id: 'brokerBuy',
      name: 'Broker (Buy)',
      columnProps: {
        body: (data: TradesDataResponse) => data.isImported ? data.buyerObBroker.userName : formatName(data.buyerObBroker.userName),
        field: 'buyerObBroker.userName',
        headerClassName: 'sorting--server-side',
        header: <ServerSortHeader
          label='Broker (Buy)'
          by={SortableFieldName.BuyerObBrokerUserName}
          current={sortOrder}
        />,
        sortable: true,
        sortField: SortableFieldName.BuyerObBrokerUserName
      },
      gridProps: {
        header: 'Broker (Buy)',
        body: (data) => data.isImported ? data.buyerObBroker.userName : formatName(data.buyerObBroker.userName)
      }
    },
    {
      label: 'Buy side',
      id: 'buySide',
      name: 'Buy side',
      columnProps: {
        body: DoubleLineSimple,
        field: 'buyerContactName,buyerCompany',
        headerClassName: 'sorting--server-side',
        header: <ServerSortHeader
          label='Buy Side'
          by={SortableFieldName.BuyerContactName}
          current={sortOrder}
        />,
        sortable: true,
        sortField: SortableFieldName.BuyerContactName
      },
      gridProps: {
        header: 'Buy Side',
        body: (data) => DoubleLineSimple(data, { field: 'buyerContactName,buyerCompany' } as ColumnBodyOptions)
      }
    },
    {
      label: 'Broker (Sell)',
      id: 'brokerSell',
      name: 'Broker (Sell)',
      columnProps: {
        body: (data: TradesDataResponse) => data.isImported ? data.sellerObBroker.userName : formatName(data.sellerObBroker.userName),
        field: 'sellerObBroker.userName',
        headerClassName: 'sorting--server-side',
        header: <ServerSortHeader
          label='Broker (Sell)'
          by={SortableFieldName.SellerObBrokerUserName}
          current={sortOrder}
        />,
        sortable: true,
        sortField: SortableFieldName.SellerObBrokerUserName
      },
      gridProps: {
        header: 'Broker (Sell)',
        body: (data) => data.isImported ? data.sellerObBroker.userName : formatName(data.sellerObBroker.userName)
      }
    },
    {
      label: 'Sell side',
      id: 'sellSide',
      name: 'Sell side',
      columnProps: {
        body: DoubleLineSimple,
        field: 'sellerContactName,sellerCompany',
        headerClassName: 'sorting--server-side',
        header: <ServerSortHeader
          label='Sell Side'
          by={SortableFieldName.SellerContactName}
          current={sortOrder}
        />,
        sortable: true,
        sortField: SortableFieldName.SellerContactName
      },
      gridProps: {
        header: 'Sell Side',
        body: (data) => DoubleLineSimple(data, { field: 'sellerContactName,sellerCompany' } as ColumnBodyOptions)
      }
    },
    {
      label: 'Quantity',
      id: 'quantity',
      name: 'Quantity',
      columnProps: {
        body: (data: TradesDataResponse, config: ColumnBodyOptions) => SingleLineWithAddon(data, config, data.quantity.unit),
        field: 'quantity.amount',
        bodyClassName: 'no--padding blotter-data-table__column--quantity',
        headerClassName: 'sorting--server-side',
        header: <ServerSortHeader
          label='Quantity'
          by={SortableFieldName.QuantityAmount}
          current={sortOrder}
        />,
        sortable: true,
        sortField: SortableFieldName.QuantityAmount
      },
      gridProps: {
        className: 'grid-element__quantity',
        header: 'Quantity',
        body: (data) => SingleLineWithAddon(data, { field: 'quantity.amount' } as ColumnBodyOptions, data.quantity.unit)
      }
    },
    {
      label: 'Price',
      id: 'price',
      name: 'Price',
      columnProps: {
        body: (data: TradesDataResponse, config: ColumnBodyOptions) => SingleLineWithAddon(data, config, data.price.unit),
        field: 'price.amount',
        bodyClassName: 'no--padding blotter-data-table__column--price',
        headerClassName: 'sorting--server-side',
        header: <ServerSortHeader
          label='Price'
          by={SortableFieldName.PriceAmount}
          current={sortOrder}
        />,
        sortable: true,
        sortField: SortableFieldName.PriceAmount
      },
      gridProps: {
        className: 'grid-element__price',
        header: 'Price',
        body: (data) => SingleLineWithAddon(data, { field: 'price.amount' } as ColumnBodyOptions, data.price.unit)
      }
    },
    {
      label: 'Trade date, time',
      id: 'tradeDateTime',
      name: 'Trade date, time',
      columnProps: {
        body: (data: TradesDataResponse, config: ColumnBodyOptions) => ReadableDate(data, config, 'dd LLL yyyy, HH:mm ZZZZ'),
        field: 'dateTime',
        headerClassName: 'sorting--server-side',
        header: <ServerSortHeader
          label='Trade Date, Time'
          by={SortableFieldName.DateTime}
          current={sortOrder}
        />,
        sortable: true,
        sortField: SortableFieldName.DateTime
      },
      gridProps: {
        header: 'Trade Date, Time',
        body: (data) => ReadableDate(data, { field: 'dateTime' } as ColumnBodyOptions, 'dd LLL yyyy, HH:mm ZZZZ')
      }
    },
    {
      label: 'Clearing ID',
      id: 'clearingId',
      name: 'Clearing ID',
      columnProps: {
        field: 'clearingId',
        headerClassName: 'sorting--server-side',
        header: <ServerSortHeader
          label='Clearing ID'
          by={SortableFieldName.ClearingId}
          current={sortOrder}
        />,
        sortable: true,
        sortField: SortableFieldName.ClearingId
      },
      gridProps: {
        className: 'grid-element__clearing-id',
        header: 'Clearing ID',
        body: (data) => data.clearingId
      }
    },
    {
      label: 'Actions',
      id: 'actions',
      name: 'Actions',
      pinned: true,
      disabled: true,
      columnProps: {
        body: (item: TradesDataResponse) => (
          <TradeActionMenu
            item={item}
            setActionItem={setActionItem}
            contextMenu={contextMenuActions}
          />
        )
      },
      gridProps: {
        className: 'grid-element__actions',
        header: '',
        body: (data) => <TradeActionMenu
          className='margin--right--small'
          item={data}
          setActionItem={setActionItem}
          contextMenu={contextMenuActions}
        />
      }
    },
  ], [contextMenuItems, filterCount, sortOrder]);

  const [allColumns, setAllColumns] = useState<ColumnConfig[]>(allColumnsConfig);

  useEffect(() => {
    // set initial column templates just once
    if (dataTableRef.current && !initialColumnTemplates) {
      setInitialColumnTemplates(
        getGridColumnsTemplates(dataTableRef.current.getElement(), GRID_TEMPLATE_COLUMNS_CSS_VAR_NAME)
      );
    }
  }, []);

  useLayoutEffect(() => {
    if (dataTableRef.current && columnTemplates) {
      dataTableRef.current.getElement().style.setProperty(GRID_TEMPLATE_COLUMNS_CSS_VAR_NAME, columnTemplates);
    }
  }, [columnTemplates]);

  useEffect(() => {
    if (config) {
      const order = config.additional?.order;
      // add also 'tpl' prop which contains grid template - just to sort templates in one go with config columns
      const newColumns: ColumnConfig[] = allColumnsConfig.map((c, i) => ({ ...c, hidden: !config.columns.includes(c.id!), tpl: initialColumnTemplates?.[i] }));
      const newAllColumns = newColumns.toSorted(sortColumns(order));
      const newActiveColumns = newAllColumns.filter(col => config.columns.includes(col.id!));

      setActiveColumns(newActiveColumns);
      setAllColumns(newAllColumns);
      setColumnTemplates(newActiveColumns.map(c => c.tpl).join(' '));
    }
  }, [allColumnsConfig, config, isMobile]);

  useEffect(() => {
    setTradeItemSize(currentSize =>
      (dataTableRef.current?.getTable().getElementsByClassName('trade-item')[0] as HTMLDivElement | null)?.offsetHeight
      ?? currentSize
    );
  });

  useEffect(() => {
    setIsDefaultState(allColumnsConfig.length === activeColumns.length &&
      allColumnsConfig.toSorted(sortColumns()).every((col, i) => activeColumns[i]?.id === col.id));
  }, [activeColumns, allColumnsConfig, isMobile]);

  if (error) {
    return <ServiceCallError error={error} />;
  }

  const handleRowClick = (value: TradesDataResponse): void => {
    onTradeSelected(value);
  };

  const COMMON_PROPS = {
    className: 'blotter-data-table',
    dataKey: 'id',
    emptyMessage: SEARCH_EMPTY_MESSAGE,
    loading: isLoading,
    metaKeySelection: false,
    onSelectionChange: (e: DataTableSelectionSingleChangeEvent<TradesDataResponse[]>): void => handleRowClick(e.value),
    scrollable: true,
    scrollHeight: 'flex',
    selection: selectedTrade,
    value: trades,
    virtualScrollerOptions: {
      className: 'grow-to-fill',
      itemSize: DEFAULT_GRID_ROW_HEIGHT, // itemSize is required to display proper amount of items
      onLazyLoad,
      lazy: true
    }
  } as Partial<DataTablePropsSingle<TradesDataResponse[]>>;

  const MOBILE_PROPS = {
    cellClassName: () => 'no--padding',
    className: clsx(COMMON_PROPS.className, 'row--no-border'),
    selectionMode: 'single' as DataTablePropsSingle<TradesDataResponse[]>['selectionMode'],
    showHeaders: false,
    virtualScrollerOptions: {
      ...COMMON_PROPS.virtualScrollerOptions,
      itemSize: tradeItemSize, // itemSize is required to display proper amount of items
      onScroll: onTableScroll,
      numToleratedItems: 20 // render more items to avoid items blinking
    },
    children: (
      <Column
        body={(data: TradesDataResponse): JSX.Element =>
          TradeItem({
            activeColumns,
            data,
            handleRowClick,
          })
        }
      />
    ),
  } as Partial<DataTablePropsSingle<TradesDataResponse[]>>;

  const DESKTOP_PROPS = {
    selectionMode: 'single',
    onSort: handleOrderChange,
    children: allColumns.map(col => <Column
      key={col.id}
      {...col.columnProps}
      bodyClassName={clsx({ hidden: col.hidden }, col.columnProps.bodyClassName)}
      headerClassName={clsx({ hidden: col.hidden }, col.columnProps.headerClassName)}
      // hidden={col.hidden} NOTE: `hidden` property removes column from the DOM
    />)
  } as Partial<DataTablePropsSingle<TradesDataResponse[]>>;

  return (
    <>
      <DataTable
        key={`${ isMobile }`}
        ref={dataTableRef}
        {...COMMON_PROPS}
        {...(isMobile ? MOBILE_PROPS : DESKTOP_PROPS)}
      />
      <ContextMenu className={!actionItem ? 'blotter-data-table__action-menu-init-state' : ''} ref={contextMenuActions} model={contextMenuActionsItems} />
      <ConfigurationTabs
        className='blotter-grid-column-configuration'
        type={GridConfigurationType.Column}
      />
      {config && <GridColumnConfiguration
        className='blotter-grid-column-panel'
        config={config}
        setConfig={setConfig}
        heading='Customize Columns'
        propkey='id'
        searchFilter={false}
        allColumns={allColumns}
        showBackdrop={isMobile}
        restorable
      />}
    </>
  );
};

export { BlotterDataTable };
export default BlotterDataTable;
