import { useEffect, useState, useMemo } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import Ribbon from 'components/Ribbons/Templates/Ribbon';

import { SanctiondItem } from "modules/Sanctions/Models/SanctionedItems"
import SanctionStatus from 'modules/Sanctions/Templates/SanctionStatus';
import SanctionLabel from 'modules/Sanctions/Templates/SanctionLabel';
import { apiLegalEntityResponse, apiVesselSanctionResponse } from 'modules/Sanctions/Models';

import { parseVesselProps, parseEntityProps, type Property, type Status } from 'modules/Sanctions/Models/Parsers';

import { asEncoded } from 'helpers/Utils/string';

import { ResultsModeEnum, SanctionTypeAPI } from '../../Models/Enums';
import { useAuditSanctionsTrack } from '../../Services/SanctionsAPI';

import './SanctionDetail.scss';

type DetailOpts = {
  parseProps: boolean,
  trackView?: boolean,
}

const defaultOpts: DetailOpts = {
  parseProps: true,
  trackView: true,
} 

interface IProps {
  selectedItem: SanctiondItem | apiLegalEntityResponse | apiVesselSanctionResponse;
  resultsMode: ResultsModeEnum;
  opts?: DetailOpts
}

function SanctionDetails(props: IProps) {

  const { selectedItem, resultsMode } = props;
  const opts: DetailOpts = { ...defaultOpts, ...props.opts}

  const { trigger } = useAuditSanctionsTrack(selectedItem?.id);

  const textProps = useMemo<Property[]>(() => {

    if (!selectedItem) return [];

    switch(opts.parseProps) {
      case true:
        const { general } = resultsMode === ResultsModeEnum.VesselSanctions 
          ? parseVesselProps(selectedItem as apiVesselSanctionResponse) 
          : parseEntityProps(selectedItem as apiLegalEntityResponse);

        return general;
      case false:

        return (selectedItem as SanctiondItem).general 
    }

  }, [ resultsMode, selectedItem ]);

  const statusProps = useMemo<Status[]>(() => {

    if (!selectedItem) return [];

    switch(opts.parseProps) {
      case true:
        const { status } = resultsMode === ResultsModeEnum.VesselSanctions
          ? parseVesselProps(selectedItem as apiVesselSanctionResponse) 
          : parseEntityProps(selectedItem as apiLegalEntityResponse);
        return status;
      case false:
        return (selectedItem as SanctiondItem).status 
    }
  }, [resultsMode, selectedItem])

  useEffect(() => {
    if (!opts.trackView || !selectedItem) return;

    /** Tracking of a view should only happen if it's ready and we want it too  */
    trigger({
      id: selectedItem.id,
      ProviderName: selectedItem.providerName,
      SanctionType: SanctionTypeAPI[ResultsModeEnum[resultsMode] as keyof typeof ResultsModeEnum]
    });

  }, [ trigger ])

  if (!selectedItem || !textProps || !statusProps) {
    return <>
      <div>Please select at item from the table…</div>
    </>
  }

  return <>
    { resultsMode === ResultsModeEnum.VesselSanctions &&
      (selectedItem as any).shipDetailsNoLongerMaintained === 1 &&
      <Ribbon
        className='sanction-details__no-data-ribbon'
        closable={false}
        severity='info'
        icon={<i className='iconoir-info-circle icon--tiny' />}
        text='Vessel Data is no longer maintained'
    /> }
    <div className="definition-list__container">
      {textProps.map(([label, value], index) =>
        <div key={index} className="definition-list__item">
          <dt>{label}</dt>
          <dd>{value}</dd>
        </div>
      )}
    </div>
    <div className="grow-to-fill overflow--hidden">
      <DataTable
          value={statusProps}
          dataKey={(d) => asEncoded(d, true)}
          removableSort
          className="sanctions-table--detail grow-to-fill header--micro"
          scrollable scrollHeight="flex"
        >
        <Column
          header="Type"
          body={SanctionLabel}
        />
        <Column
          field="value"
          body={SanctionStatus}
          header="Risk Level"
          className="sanction-status"
        />
      </DataTable>
    </div>
  </>;
}

export default SanctionDetails;
