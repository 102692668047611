import { ReactElement, ReactNode, useMemo } from 'react';

import NotAvailable from 'components/NotAvailable';
import SpecDetails from 'components/SpecDetails';
import { DistListLinkExpireEnum } from 'modules/DistList/Models/Enums';
import { DATE_FORMAT } from 'modules/DistList/Models/Consts';
import { SingleLineStatus } from 'helpers/DataTable/Templates/ColumnTemplates/SingleLineStatus';

import type { apiSingleMailEmailDetails } from 'modules/DistList/Models/apiResponse';

interface MessageProps {
  details: apiSingleMailEmailDetails | undefined;
  loading: boolean;
}

const SingleMessageDetails = (props: MessageProps): ReactElement => {
  const { details, loading } = props;

  const data = useMemo(() => details ?
    new Map<string, ReactNode>([
      ['Subject', details.subject],
      ['Message Body', details.emailBody],
      ['Attachment', details.attachmentNames.join('\n') || <NotAvailable label='Empty' />],
      ['Date', details.messageDate.toFormat(DATE_FORMAT)],
      ['Link expiry period', DistListLinkExpireEnum[details.attachmentExpiry]],
      ['Link status', SingleLineStatus(details, { field: 'attachmentStatus' })],
    ]) :
    null,
    [details]
  );

  return <div className='single-message-details__container grow-to-fill position--relative overflow--y'>
    <SpecDetails className='single-message-details__spec' data={data} isLoading={loading} />
  </div>;
};

export default SingleMessageDetails;
