export enum Group {
  Instrument,
  Buyer,
  Seller,
  Price,
  Quantity,
  'OB Broker',
  'Clearing ID',
  Type
}

// local only
export enum BlotterEntitySearchFieldsEnum {
  Price = -1,
  Quantity = -2,
  ClearingId = -3,
  Type = -4
}

export enum ValidationMode {
  None,
  All,
  CompanyOnly,
  TouchedOnly,
}

export enum SortOrder {
  Asc = 1,
  Desc
}

export enum SortableFieldName {
  Instrument = 'Instrument',
  Type = 'Type',
  BuyerObBrokerUserName = 'BuyerObBrokerUserName',
  BuyerContactName = 'BuyerContactName',
  SellerObBrokerUserName = 'SellerObBrokerUserName',
  SellerContactName = 'SellerContactName',
  QuantityAmount = 'Quantity/Amount',
  PriceAmount = 'PriceAmount',
  DateTime = 'DateTime',
  ClearingId = 'Clearing/Id'
}

export enum TradeType {
  Outright = 'Outright',
  Spread = 'Spread',
}

export enum TradeItemAction {
  Clone = 'Clone',
  Copy = 'CopyToClipboard',
}

export enum ExportOption {
  Default,
  Custom
}