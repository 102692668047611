import { ShippingFixturesService } from "./FixturesService";
import useSWR from 'swr';

import { asEncoded } from "helpers/Utils/string";

import { apiGetFixtureSummaries } from '../Models/apiRequest';


/**
 * Custom hook to load chunked shipping fixture data based on a passed
 * sert of search queries
 *
 * @param {apiGetFixtureSummaries} params
 * @returns {{ data: any; error: any; isLoading: any; mutate: any; }}
 */
export const useShippingFixtures = (params: apiGetFixtureSummaries) => {

	const cachekey: string = `shipping-fixtures.${asEncoded(params)}`;

	const { data, error, isLoading, mutate} = useSWR(
		params ? cachekey : null,
		() => ShippingFixturesService.getFixtures(params),
		{ revalidateOnFocus: false }
	)

	return { data, error, isLoading, mutate } 

}

export const useFixtureSanctionDetails = (fixtureid: string) => {

	
	const { data, error, isLoading } = useSWR(
		fixtureid ? `fixture-sanction-${fixtureid}` : null,
		() => ShippingFixturesService.getFixtureDetails(fixtureid),
		{ revalidateOnFocus: false }
	)

	return { data, error, isLoading }

}



export const useSanctionSearchSanpshot = (snaphotid: string, fixtureid: string, execute: boolean) => {
	
	const { data, error, isLoading } = useSWR(
		execute ? `snapshot-${snaphotid}-${fixtureid}` : null,
		() => ShippingFixturesService.getSnaphot(fixtureid, snaphotid),
		{ revalidateOnFocus: false }
	)

	return { data, error, isLoading }
}