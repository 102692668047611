import { ColumnBodyOptions } from 'primereact/column';

import { Status } from 'modules/Sanctions/Models/Parsers';

export const SanctionLabel = (data: Status, config: ColumnBodyOptions) => {

	let [label] = data;

	return <>{label}</>
}

export default SanctionLabel;