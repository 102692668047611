import { Checkbox, type CheckboxChangeEvent } from 'primereact/checkbox';
import { useSortable } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import clsx from 'clsx';

import type { GridColumn } from 'components/OBXUser/Model/ProfileResult';

import styles from './SortableItem.module.scss';

interface SortableItemProps {
  propkey: string;
  isDragging: boolean;
  item: GridColumn;
  active?: boolean;
  placeholder?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
}

export default function SortableItem(props: SortableItemProps): JSX.Element {
  const { active, isDragging, item, onChange, placeholder, propkey } = props;

  const isDisabled = item.disabled || item.pinned;
  const sortableNode = { id: item.id!, disabled: isDisabled };

  const {
    activeIndex,
    attributes,
    index,
    listeners,
    overIndex,
    setNodeRef: setSortableNodeRef,
    transition,
  } = useSortable(sortableNode);

  const {
    setNodeRef: setDroppableNodeRef
  } = useDroppable(sortableNode);

  const style = {
    transition
  };

  const prop: string = item[propkey as keyof GridColumn] as string;
  const showIndicator = typeof index === 'number' && overIndex === index;
  const placeBefore = showIndicator && index < activeIndex;
  const placeAfter = showIndicator && index > activeIndex;

  return (<>
    {placeBefore && <hr className={styles.placeIndicator} />}
    <li
      key={`col-${index}-${item.name}`}
      ref={(node) => {
        setSortableNodeRef(node);
        setDroppableNodeRef(node);
      }}
      style={style}
      {...attributes}
      {...listeners}
      className={clsx(styles.sortableItem, {
        [styles.grabbing]: isDragging,
        [styles.grab]: !isDragging,
        [styles.notAllowed]: isDisabled,
        [styles.disabled]: isDisabled,
        [styles.placeholderItem]: placeholder,
        [styles.activeItem]: active
      })}
    >
      <Checkbox
        id={prop}
        name={prop}
        value={prop}
        disabled={isDisabled}
        checked={!!item.checked}
        onChange={(e) => onChange && onChange(e)}
      />
      <label htmlFor={item.name}>{item.label}</label>
      {item.pinned ?
        <span className='iconoir-pin icon--tiny'>Pinned</span> :
        <i className={clsx('iconoir-expand icon--tiny', styles.dragAndDropIcon)} />
      }
    </li>
    {placeAfter && <hr className={styles.placeIndicator} />}
  </>
  );
}