import {
  forwardRef,
  useImperativeHandle,
  useState,
  type ForwardedRef,
} from 'react';
import { Button } from 'primereact/button';

interface FooterProps {
  onExport: () => Promise<void>;
  onCancel: () => void;
}

export interface FooterRefProps {
  setLoading: (arg: boolean) => void;
}

export const Footer = forwardRef((props: FooterProps, ref: ForwardedRef<FooterRefProps>): JSX.Element => {
  const { onExport, onCancel } = props;
  const [loading, setLoading] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    setLoading
  }), []);

  return <div className='space-between'>
    <Button
      autoFocus
      text
      size='small'
      severity='danger'
      onClick={onCancel}
    >
      Cancel
    </Button>
    <Button
      size='small'
      severity='success'
      loading={loading}
      onClick={onExport}
    >
      Export
    </Button>
  </div>;
});

export default Footer;