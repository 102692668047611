import { ReactElement, RefObject, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import type { Suggestion, SuggestionItem } from 'components/Autocomplete';
import Autocomplete from 'components/Autocomplete';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import {
  SearchField,
  SearchSuggestionsParsedResponse,
  SearchSuggestionsResponse
} from 'components/EntitySearch/Models/SearchEntities';
import { useSearchSuggestions } from 'components/EntitySearch/Services/SearchEntitiesAPI';

import { apiSearchSingleEmailRequest } from '../../Models/apiRequest';
import { SingleSearchGroup } from '../../Models/Enums';
import { createGroupItem, groupedEntityMapper, selectedItemsMapper } from '../../Models/Parsers';

interface ISingleSearchEntityProps {
  onRequestChanged: (data: SearchField[]) => void;
  searchItems: apiSearchSingleEmailRequest | undefined;
  searchContainerRef: RefObject<HTMLElement>;
  className?: string;
}

const SingleSearchEntity = (
  param: ISingleSearchEntityProps
): ReactElement => {
  const {
    onRequestChanged,
    className,
    searchItems,
    searchContainerRef,
  } = param;
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [value, setValue] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const { data, /* error,*/ isLoading, isValidating } = useSearchSuggestions({
    module: EntitySearchGroupEnum.DistributionList,
    fields: [
      EntitySearchFieldsEnum.EmailRecipient,
      EntitySearchFieldsEnum.EmailSubject,
    ],
    term: value,
  });

  const handleSelectedItemsChange = (
    items: SuggestionItem[],
    removedItem?: SuggestionItem
  ): void => {
    if (!removedItem) {
      const searchRequest = Object.values(items).map(
        item => ({
          searchTerm: item.searchTerm ?? '',
          searchField: item.searchField ?? EntitySearchFieldsEnum.Owner,
          metaData: [],
        })
      );
      onRequestChanged(searchRequest);
    }
  };

  useEffect(() => {
    const d =
      data as SearchSuggestionsParsedResponse<SearchSuggestionsResponse>[];
    const suggestions: Record<
      'recipients',
      SearchSuggestionsResponse[]
    > = {
      recipients: [],
    };

    // Add suggestions that will just show value that user inputs
    const itemsWithGroup: Suggestion[] = [
      createGroupItem(value, SingleSearchGroup['Attachment Name'], EntitySearchFieldsEnum.EmailAttachmentName),
      createGroupItem(value, SingleSearchGroup.Subject, EntitySearchFieldsEnum.EmailSubject)
    ];

    // Add suggestions from API
    if (data) {
      suggestions.recipients.push(
        ...d.filter(item => item.searchFieldId === EntitySearchFieldsEnum.EmailRecipient)
          .flatMap(item => item.values)
      );

      if (suggestions.recipients.length) {
        itemsWithGroup.push({
          group: SingleSearchGroup[SingleSearchGroup.Recipient],
          items: suggestions.recipients.map(
            groupedEntityMapper(SingleSearchGroup[SingleSearchGroup.Recipient])
          ),
        });
      }
    }

    // Add all to state and sort groups
    setSuggestions(itemsWithGroup
      .sort((a, b) => (a.group as string).localeCompare(b.group as string)));
  }, [data, value]);

  const selectedItems: SuggestionItem[] = useMemo(() => searchItems?.searchRequestFields?.map(
    field => {
      let group = '';
      switch(field.searchField) {
      case EntitySearchFieldsEnum.EmailRecipient:
        group = SingleSearchGroup[SingleSearchGroup.Recipient];
        break;
      case EntitySearchFieldsEnum.EmailSubject:
        group = SingleSearchGroup[SingleSearchGroup.Subject];
        break;
      case EntitySearchFieldsEnum.EmailAttachmentName:
        group = SingleSearchGroup[SingleSearchGroup['Attachment Name']];
        break;
      }

      return selectedItemsMapper(field, group);
    }) ?? [],
  [searchItems?.searchRequestFields]
  );

  return <Autocomplete
    handleInputValueChange={setValue}
    handleSelectedItemsChange={handleSelectedItemsChange}
    isLoading={isLoading || isValidating}
    selectedItems={selectedItems}
    suggestions={suggestions}
    className={className}
    placeholder="Search by attachment name, recipient, subject"
    autoExpandOnFocus={isMobile}
    useToggle={!isMobile}
    containerRef={searchContainerRef}
  />;
};

export default SingleSearchEntity;
