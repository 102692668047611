import { ReactElement } from 'react';

import NotAvailable from 'components/NotAvailable';

import { notNil } from 'helpers/Utils/misc';

export const SingleLineEmpty = <T,>(
  data: T,
  config: { field: string }
): ReactElement => {
  const { field } = config;

  let value = data[field as keyof typeof data] as string | null | unknown[];

  if (Array.isArray(value)) {
    value = value.length > 0 ? value.join(', ') : null;
  }

  return (
    <div>
      {notNil(value) && (value as string).trim() !== '' ? (
        <span>{value}</span>
      ) : (
        <NotAvailable label='Empty' />
      )}
    </div>
  );
};
