export enum SanctionSnapshotType {
	Vessel = 1,
	Entity = 2,
}

export enum AuditScoreType {
	Unknown = 0,
	NoMatchingSearch = 1,
	Sanctioned = 2,
	NonFixingBrokerSearch = 4,
	OK = 128,
}