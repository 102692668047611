import GridElement from 'components/GridElement';
import { DL_MESSAGES_STATUS_LABEL, DLMessagesStatus } from 'modules/DistList/Models/Enums';
import { shouldShowResendButton } from 'modules/DistList/Helpers';
import { SingleLineUnknown } from 'helpers/DataTable/Templates/ColumnTemplates';

import ResendButton, { type ResendButtonFlatData } from './ResendButton';
import DroppedTooltip from './DroppedTooltip';

import type { ColumnBodyOptions } from 'primereact/column';
import type { apiSingleMailEmailDetailsFlat } from 'modules/DistList/Models/apiResponse';
import type {
  DistributionListMessagesSearchResponse,
  DistributionListMessagesSearchResponseFlat
} from 'modules/DistList/Models/distribution-list-response';

interface MessageTemplateMobileProps {
  item: DistributionListMessagesSearchResponseFlat | apiSingleMailEmailDetailsFlat;
  onMessageResend: ((data: ResendButtonFlatData) => void);
  sentByVisible?: boolean;
}

const MessageTemplateMobile = (props: MessageTemplateMobileProps): JSX.Element => {
  const { item, onMessageResend, sentByVisible = true } = props;

  return (
    <div className='list-element' key={item.id}>
      <GridElement
        header='Recipient'
        body={item.emailAddress}
      />
      <GridElement
        header='Status'
        body={<span className='list-element-slanted'>
          {[DLMessagesStatus.dropped].includes((item.status as DLMessagesStatus) ?? DLMessagesStatus.null) ?
            (<>{DL_MESSAGES_STATUS_LABEL[DLMessagesStatus.dropped]}<DroppedTooltip /></>) :
            DL_MESSAGES_STATUS_LABEL[item.status as DLMessagesStatus ?? DLMessagesStatus.null]}
        </span>}
      />
      <GridElement
        header='Attachment'
        body={<span className='list-element-slanted'>
          {item.attachmentDownloaded ? 'Downloaded' : 'NOT downloaded'}
        </span>}
      />
      <GridElement
        header='Opening IP Address'
        body={SingleLineUnknown(item as any, { field: 'ipAddress' } as ColumnBodyOptions)}
      />
      {shouldShowResendButton(item) &&
        <GridElement
          header=''
          body={<>
            {sentByVisible && <span>Sent by {(item as DistributionListMessagesSearchResponse).requestingUserName ?? item.requestingUserEmailAddress ?? 'Unknown'}</span>}
            <ResendButton
              data={item}
              callback={onMessageResend}
            />
          </>}
        />}
    </div>
  );
};

export default MessageTemplateMobile;