import { ChangeEvent, KeyboardEvent, ReactElement, RefObject, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import { ToastMessageRef, ToastSeverity } from 'components/ToastMessage';

import { CreateSingleParams } from '../../Models/distribution-list-create-request';
import { DistListLinkExpireEnum } from '../../Models/Enums';
import { DistListApiService } from '../../Services/DistListService';
import ListEditEmail from '../ListEdit/ListEditEmail';

import { notNil } from 'helpers/Utils/misc';
import { stringToEmailAddresses } from 'helpers/Utils/string';

interface SingleNewMessageProps {
  handleCloseSidePanel: () => void;
  toast: RefObject<ToastMessageRef>;
}

const EXPIRY_OPTIONS = Object.values(DistListLinkExpireEnum)
  .filter(key => isNaN(Number(key)))
  .map((el, index) => (
    {label: el, value: index}
  ));

const SingleNewMessage = (props: SingleNewMessageProps): ReactElement => {
  const { handleCloseSidePanel, toast } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const [ request, setRequest ] = useState<CreateSingleParams>({
    recipients: [],
    attachmentExpiry: DistListLinkExpireEnum['30 days'],
  } as CreateSingleParams);
  const [ isNewEmailInputVisible, setIsNewEmailInputVisible ] = useState<boolean>(true);
  const [ isEmailError, setIsEmailError ] = useState<boolean>(false);
  const [ isNewEmailError, setIsNewEmailError ] = useState<boolean>(false);
  const [ failedEmails, setFailedEmails ] = useState<string[]>([]);
  const [ isSaving, setIsSaving ] = useState<boolean>(false);

  const mutateList = (mutation: Partial<CreateSingleParams>): void => {
    setRequest(c => ({ ...c, ...mutation }));
  };

  const handleParseEmail = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>):void => {
    if (e.target.value.trim() === '' && request.recipients.length > 0) {
      setIsNewEmailInputVisible(false);
    }

    const { passed, failed } = stringToEmailAddresses(e.target.value);

    if (passed.length) {
      const concatPassed = [...request.recipients , ...passed];

      mutateList({ recipients: [...new Set(concatPassed)] });

      if (!failed.length) {
        setIsNewEmailInputVisible(false);
        setIsNewEmailError(false);

        return;
      }
    }

    if (failed.length) {
      setIsNewEmailError(true);
      setFailedEmails(failed);

      e.target.value = `${ failed.join(', ') }`;
    }

    if (e.target.value === '') {
      setIsNewEmailError(false);
      setFailedEmails([]);
    }

  }, [request.recipients]);

  const handleKeyUp = (e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      (e.target as HTMLTextAreaElement).blur();
    }
  };

  const addButtonHandler = useCallback(():void => {
    setIsNewEmailInputVisible(true);
  }, []);

  const emailErrorMessage = (): ReactElement => (
    <small className="message-invalid">
      {failedEmails.length > 0 ?
        `Some emails addresses are invalid: ${ failedEmails.join(', ') }` :
        'Please enter a valid email'}
    </small>
  );

  const handleSave = useCallback(async ():Promise<void> => {
    if (request.recipients.length > 0 && !isEmailError && !isNewEmailError) {
      setIsSaving(true);
      await DistListApiService.CreateSingle(request)
        .then(response => {
          if (notNil(response)) {
            window.open(`mailto:${ response?.id }@${ window.location.host }`);
            toast.current?.replace({
              title: 'Created Successfully',
              message: 'A new message has been successfully created',
              severity: ToastSeverity.SUCCESS
            });
            handleCloseSidePanel();
          } else {
            toast.current?.replace({
              title: 'Error',
              message: 'Sorry, something has gone wrong. Please try again later.',
              severity: ToastSeverity.ERROR,
            });
          }
          setIsSaving(false);
        });
    }

    if (request.recipients.length === 0) {
      setIsNewEmailError(true);
    }
  }, [handleCloseSidePanel, isEmailError, isNewEmailError, request, toast]);

  useEffect(() => {
    request.recipients.length === 0 && setIsNewEmailInputVisible(true);
  }, [request.recipients]);

  return (
    <>
      {!isMobile && <header>
        Create New Message
        <Button
          text
          icon='iconoir-xmark icon--tiny p-button-icon-only'
          className='close-button'
          onClick={handleCloseSidePanel}
        />
      </header>}
      <div className='overflow--y direction--column grow-to-fill position--relative'>
        {isMobile && <Button
          size="small"
          text
          className="plain-text back-button"
          icon={'iconoir-nav-arrow-left icon--small'}
          onClick={handleCloseSidePanel}
        >
          Back to list
        </Button>}
        <form className='direction--column distlist-dl-panel__form'>
          <div className='single-details__form'>
            <div className='form-input__container'>
              <label>Link Expiry period*</label>
              <Dropdown
                options={EXPIRY_OPTIONS}
                onChange={(e:DropdownChangeEvent) => mutateList({ attachmentExpiry: e.value })}
                value={request.attachmentExpiry}
              />
            </div>

            <div className='form-input__container'>
              <label htmlFor='dl-email-list'>Email List*</label>
              {request.recipients.length ? (
                <div className='distlist-dl-email-table-container'>
                  {request.recipients.map((email, index) => (
                    <ListEditEmail
                      email={email}
                      index={index}
                      recipients={request.recipients}
                      mutateList={mutateList}
                      setIsEmailError={setIsEmailError}
                      key={`${ email }`}
                    />
                  ))}
                  {isNewEmailInputVisible && (
                    <>
                      <InputText
                        autoFocus
                        placeholder='Add email address'
                        className={clsx('distlist-dl-email-table-new', {
                          'p-invalid': isNewEmailError,
                        })}
                        onBlur={handleParseEmail}
                        onKeyUp={handleKeyUp}
                      />
                      {isNewEmailError && emailErrorMessage()}
                    </>
                  )}
                </div>
              ) : (
                <>
                  <InputTextarea
                    placeholder='Add recipients'
                    id='dl-email-list'
                    className={clsx('distlist-dl-email-list', {
                      hidden: !isNewEmailInputVisible,
                      'p-invalid': isNewEmailError,
                    })}
                    autoResize
                    onFocus={(e): void => e.target.select()}
                    onBlur={handleParseEmail}
                    onKeyUp={handleKeyUp}
                  />
                  {isNewEmailError && emailErrorMessage()}
                </>
              )}
              <Button
                id="dl-email-list"
                className={clsx('distlist-add-dl-button', {
                  'not-visible': isNewEmailInputVisible,
                })}
                size='small'
                text
                icon='iconoir-plus icon--tiny'
                type='button'
                onClick={addButtonHandler}
              >
                Add Recipients
              </Button>
            </div>

          </div>
        </form>
      </div>
      <footer>
        <Button
          size='small'
          severity='success'
          loading={isSaving}
          onClick={handleSave}
        >
          Create
        </Button>
      </footer>
    </>);
};

export default SingleNewMessage;