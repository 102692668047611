import {  GridColumn } from 'components/OBXUser/Model/ProfileResult';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { eventBus } from 'server/EventBus';

import { GridConfigPanelEvents, GridConfigurationType } from 'components/GridColumnConfiguration';

import styles from './Styles.module.scss'

export const ProductColumnSelectItem = (opt: GridColumn):JSX.Element => {
	const [ product, unit ] = opt.label.split(",");

	return <div className={styles.dropDownItem}>
		<span>{ product }</span><span>{ unit }</span>
	</div>
}

export const ProductColumnValueItem = (opt: GridColumn):JSX.Element => {

	if (!opt) return <></>;

	const [ product, ] = opt.label.split(",");

	return <div className={styles.dropDownItem}>
		<span>{ product }</span>
	</div>
}

type FooterParams = {
	 callback: () => void
}

export const ProductVisabilityFooter = (opt: FooterParams): JSX.Element => {
	
	const { callback } = opt;
	
	return <div className={styles.columnConfig}>
		<Button
			size="small"
			text
			icon='iconoir-grid-plus icon--small'
			onClick={() => {
				eventBus.dispatch(
					GridConfigPanelEvents.PANEL_VISIBILITY_CHANGE,
					{ panel: GridConfigurationType.Column }
				)

				callback();
			}}
			className={clsx(
				'no-background'
			)}
			>
			Add Product
		</Button>
		Configure which products from this package should be accessible for display in the grid
	</div>
}