import { useState, useEffect, useReducer } from 'react';
import { clsx } from 'clsx';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { useSwipeable } from 'react-swipeable';
import { useMediaQuery } from "react-responsive";

import { ReadableDate, SingleLine } from 'helpers/DataTable/Templates/ColumnTemplates';
import { RowClickEvent } from 'models/shared/Events';

import Subnavigation from '../Surveillance/Components/Subnavigation';

import { useShippingFixtures } from './Services/hooks';
import { apiGetFixtureSummaries } from './Models/apiRequest';
import { ShippingFixtureSummary } from './Models/ShippingFixtures'

import { SanctionedItem, SanctionItemType } from './Tempaltes/SanctionItemStatus';
import FixtureDetail from './Components/FixtureDetail';

import styles from './ShippingFixtures.module.scss';


enum QueryMutation {
  Page = 0,
  Query = 1,
}

type QueryMutationAction = {
  type: QueryMutation,
  payload: { skip?: number, query?: any }
}

function ShippingFixtures() {

  const take: number = 100;

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" })

  const [ fixtures, setFixtures ] = useState<ShippingFixtureSummary[]>([]);
  const [ maxItems, setMaxItems ] = useState<number>();
  const [ selectedFixture, setSelectedFixture ] = useState<ShippingFixtureSummary | null>();
  
  const reducer = (current: apiGetFixtureSummaries, action:QueryMutationAction ): apiGetFixtureSummaries => {
    switch(action.type) {

      case QueryMutation.Query:
        const { query } = action.payload;
        
        /** When the query changes - we can empty the current fixtures */
        setFixtures([]);
        return { ...current, query }

      case QueryMutation.Page:
      default:				
        const { skip } = action.payload;
      
        if (!skip) return current;

        return { ...current, skip }
    }
  }

  const [ query, dispatchQuery ] = useReducer(reducer, {skip: 0, take})

  // TODO - better handling of the paging/chunking properties
  const { data, error, isLoading } = useShippingFixtures(query)

  useEffect(() => {
    if (!data) return;

    const { items, totalRecords } = data;

    /** 
     * Add this chunk of data to the items rendered into 
     * the datagrid
    **/
    setFixtures(current => [...current, ...items]);

    setMaxItems( totalRecords );


  }, [data])

  const onSwipedRight = (): void => setSelectedFixture(null);


  const gestures = useSwipeable({
    onSwipedRight
  })


  return <>
    <Subnavigation />
    <main 
      className={clsx(
        styles.container, 'grow-to-fill',
        selectedFixture && 'drawer--active'
      )}
      data-cols={selectedFixture ? '9,3' : '12,0'}
      data-drawer-style='slide'
      data-drawer-position='alongside-right'
    >
      <section className='grow-to-fill overflow--hidden'>
        { fixtures &&
          <DataTable
            loading={isLoading}
            value={ fixtures }
            className={styles.datatable}
            scrollable
            scrollHeight='flex'
            header={<div>Fixtures</div>}
            onRowClick={(e: RowClickEvent<ShippingFixtureSummary>) => {
              setSelectedFixture(e.data);
            }}
            selectionMode="single" 
            selection={ selectedFixture }
            virtualScrollerOptions={{
              autoSize: true,
              className: 'grow-to-fill',
              lazy: true,
              itemSize: isTabletOrMobile ? 176 : 36,
              onLazyLoad: ({last}) => {
                if (last === fixtures.length && last < maxItems!) {
                  /** 
                   * The bottom item in the virtual scroll state is the last item
                   * currently loaded, so we can dispatch a new mutation to the
                   * query state to load a new chunk of data
                  **/
                  dispatchQuery({
                    type: QueryMutation.Page,
                    payload: { skip: last }
                  })
                }
              }
            }}
          >
            <Column 
              field='fixtureNumber' 
              header='Fixture ID'
              className='direction--column align-items--start'  
              body={(d, c) => <SingleLine data={d} config={c} opts={{ includeLabel: true }}/>} 
            />
            <Column 
              field='charterPartyDate' 
              header='CP Date'
              className='direction--column align-items--start'  
              body={(d, c) => ReadableDate<ShippingFixtureSummary>(d, c, 'dd LLL yyyy', {includeLabel: true})} 
            />
            <Column 
              field='brokers.list' 
              header='Broker(s)'
              className='direction--column align-items--start' 
              body={(d, c) => <SingleLine data={d} config={c} opts={{ includeLabel: true }}/>} 
            />
            <Column 
              field='vessel' 
              header='Vessel' 
              body={(d, c) => <SanctionedItem fixture={d} config={c} type={SanctionItemType.Vessel} />}
              pt={{ bodyCell: { className: clsx('no--left-padding', styles.cell )} }}
            />
            <Column 
              field='owner' 
              header='Owner'
              body={(d, c) => <SanctionedItem fixture={d} config={c} type={SanctionItemType.Entity} />} 
              pt={{ bodyCell: { className: clsx('no--left-padding', styles.cell, 'overflow--hidden' )} }}
            />
            <Column 
              field='charterer' 
              header='Charterer' 
              body={(d, c) => <SanctionedItem fixture={d} config={c} type={SanctionItemType.Entity} />} 
              pt={{ bodyCell: { className: clsx('no--left-padding', styles.cell, 'overflow--hidden' )} }}
            />
          </DataTable>
        }
      </section>
      { selectedFixture && 
        <aside className={styles.aside} {...gestures}>
          <FixtureDetail fixtureid={selectedFixture.id}/>
          <Button 
            text
            size='small'
            icon='iconoir-xmark icon--small'
            onClick={() => setSelectedFixture(null)}
          />
        </aside>
      }
      { error &&
        <>{ error.toString() }</>
      }
    </main>
  </>
}

export default ShippingFixtures;
