import { type MouseEvent, type TouchEvent } from 'react';
import { MouseSensor, TouchSensor } from '@dnd-kit/core';

const handler = ({ nativeEvent }: MouseEvent | TouchEvent): boolean => {
  if ((nativeEvent.target as HTMLElement).tagName === 'INPUT') {
    return false;
  }

  return true;
};

export class CustomMouseSensor extends MouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as 'onMouseDown', // funny that eventName has to be of type 'onMouseDown', otherwise the TS shouts ¯\_(ツ)_/¯
      handler,
    },
  ];
}

export class CustomTouchSensor extends TouchSensor {
  static activators = [
    {
      eventName: 'onTouchStart' as 'onTouchStart',
      handler
    },
  ];
}