import { ReactNode } from 'react';
import clsx from 'clsx';

import BorealisBar from 'components/BorealisBar';

import styles from './SpecDetails.module.scss';

interface SpecDetailsProps {
    className?: string;
    itemClassName?: string;
    data?: Map<string, ReactNode> | null;
    isLoading?: boolean;
}

export default function SpecDetails (props: SpecDetailsProps): JSX.Element {
    const { className, itemClassName, data, isLoading } = props;

    return <div className={clsx(styles.container, className)}>
        {data && !isLoading ? Array.from(data.keys()).map((key, index) => (
            <div key={index} className={clsx(styles.item, itemClassName)}>
            <dt>{key}</dt>
            <dd>{data.get(key)}</dd>
        </div>
        )) : <BorealisBar />}
    </div>
  }